import styled, { css } from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    position: relative;
`;

export const OptionsWrapper = styled.div`
    padding: 8px 0;
`;

const optionHover = css`
    &:hover {
        background-color: ${({ theme }) => theme.colors.grey200()};
    }
`;

export const Option = styled.button<{ $isSelected: boolean }>`
    width: 100%;
    padding: 8px 20px;
    color: ${({ theme, $isSelected }) => ($isSelected ? theme.colors.base(0.5) : theme.colors.base())};
    ${optionHover};
    text-align: left;
`;

export const OptionLabel = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SelectedOption = styled.button<{ $isOpen: boolean }>`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 4px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.large}px;
    background-color: ${({ $isOpen, theme }) => ($isOpen ? theme.colors.base(0.04) : 'transparent')};
    padding: 3px 12px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        gap: 6px;
        padding: 8px 8px 8px 12px;
    }
`;

export const ButtonAddOrganization = styled.button`
    width: 100%;
    padding: 6px 20px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    ${optionHover};
`;

export const Label = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
`;
