import React, { FC } from 'react';
import { Arrow, Typography } from '@invoicebox/ui';
import { useTheme } from 'styled-components';
import * as S from './styles';

export type TProps = {
    title: string;
    onBack: () => void;
};

export const DrawerMenuTitleHeader: FC<TProps> = ({ title, onBack }) => {
    const theme = useTheme();

    return (
        <S.Wrapper>
            <S.BackButton type="button" onClick={onBack}>
                <Arrow palette={{ open: theme.colors.base() }} defaultRotate={270} isOpen />
            </S.BackButton>
            <Typography variant="headline3">{title}</Typography>
        </S.Wrapper>
    );
};
