import React, { FC } from 'react';
import { Scrollbar, Typography } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import { TaskRowWidget } from 'src/stories/docs/widgets/TaskRowWidget';
import * as S from './styles';
import { Task } from '../../../../../store/Task/entity';
import { useLargeTablet } from '../../../../../hooks/useMedia';
import { DateRangePicker } from '../../../../../stories/docsTable/DateRangePicker';

type TProps = {
    taskList: Array<Task>;
    selectedTaskId?: number;
    onSelectTask: (task: Task) => void;
    createdAtFilter: [Date, Date] | null;
    setCreatedAtFilter: (value: [Date, Date] | null) => void;
    isLoading: boolean;
};

const TasksTable: FC<TProps> = ({
    taskList,
    selectedTaskId,
    onSelectTask,
    createdAtFilter,
    setCreatedAtFilter,
    isLoading,
}) => {
    const intl = useIntl();
    const isLargeTablet = useLargeTablet();

    return (
        <S.Wrapper $isLoading={isLoading}>
            <S.Head>
                <Typography variant="headline6">
                    {intl.formatMessage({
                        id: 'Отправленные документы',
                        defaultMessage: 'Отправленные документы',
                    })}
                </Typography>
                <DateRangePicker value={createdAtFilter} onChange={setCreatedAtFilter} />
            </S.Head>
            <S.Columns>
                <Typography variant="bodyMRegular">
                    {intl.formatMessage({
                        id: '№ отправления',
                        defaultMessage: '№ отправления',
                    })}
                </Typography>
                <Typography variant="bodyMRegular">
                    {intl.formatMessage({
                        id: 'Дата / период',
                        defaultMessage: 'Дата / период',
                    })}
                </Typography>
            </S.Columns>
            {taskList.length === 0 ? (
                <S.EmptyLabel variant="bodyMRegular">
                    {intl.formatMessage({ id: 'Документы отсутствуют', defaultMessage: 'Документы отсутствуют' })}
                </S.EmptyLabel>
            ) : (
                <Scrollbar maxHeight={isLargeTablet ? 240 : 383}>
                    {taskList.map((taskItem) => (
                        <TaskRowWidget
                            checked={selectedTaskId === taskItem.id}
                            key={taskItem.id}
                            task={taskItem}
                            onClick={onSelectTask}
                        />
                    ))}
                </Scrollbar>
            )}
        </S.Wrapper>
    );
};

export default TasksTable;
