import styled from 'styled-components';

export const ButtonSelectClientWrapper = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}
    ${({ theme }) => theme.mixins.getTypography('regular12')};
    flex-direction: column;
    padding: 0 8px;
    width: 88px;
    border-right: 1px solid ${({ theme }) => theme.colors.grey200()};
`;

export const SelectClientIconContainer = styled.div`
    width: 36px;
    height: 36px;
    border: 1px dashed ${({ theme }) => theme.colors.grey()};
    border-radius: 50%;
    ${({ theme }) => theme.mixins.flexCenterCenter}
    margin-bottom: 6px;
`;
