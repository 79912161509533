import styled from 'styled-components';

export const MainMenuItemsWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white()};
    border-radius: 14px;
    padding: 12px 0;
    width: 270px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-shrink: 0;
    transition: ${({ theme }) => theme.decorations.transition.base};
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grey500()};
    margin: 3px 0;
`;

export const SecondaryMenuItemsWrapper = styled.div`
    position: absolute;
    right: -270px;
    top: 0;
    height: 100%;
    width: 270px;
    padding: 12px 0;
    border-left: 1px solid ${({ theme }) => theme.colors.grey500()};
    display: flex;
    flex-direction: column;
    gap: 8px;
    transition: ${({ theme }) => theme.decorations.transition.base};
    background-color: ${({ theme }) => theme.colors.white()};
    border-radius: 0 14px 14px 0;
`;

export const Wrapper = styled.div<{ $isOpenedSecondaryItems: boolean }>`
    position: relative;
    z-index: 2;
    width: 270px;

    ${MainMenuItemsWrapper} {
        border-radius: ${({ $isOpenedSecondaryItems }) => ($isOpenedSecondaryItems ? '14px 0 0 14px' : '14px')};
    }

    ${SecondaryMenuItemsWrapper} {
        width: ${({ $isOpenedSecondaryItems }) => ($isOpenedSecondaryItems ? 270 : 0)}px;
        right: ${({ $isOpenedSecondaryItems }) => ($isOpenedSecondaryItems ? -270 : 0)}px;
        visibility: ${({ $isOpenedSecondaryItems }) => ($isOpenedSecondaryItems ? 'visibility' : 'hidden')};
    }
`;
