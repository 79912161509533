import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import * as S from './styles';
import DocumentReceiveMethods from './components/DocumentReceiveMethods';
import OrderContainerTable from './components/OrderContainerTable';
import DocsHeaderContentPageWidget from '../../../components/DocsHeaderContentPageWidget';
import { getAllCounterparties } from '../../../store/Counterparty/selectors';
import { BUSINESS_OFFICE_PATHS } from '../../businessOffice/paths';
import { getDocumentGeneratorSettingList } from '../../../store/DocumentGeneratorSetting/selectors';
import { useMobile } from '../../../hooks/useMedia';
import Button from '../../../stories/common/Button';
import {
    getArrayStringsFromQueryString,
    getQueryStringFromArrayString,
    useQueryFilter,
} from '../../../hooks/useQueryFilter';
import {
    COUNTERPARTY_ID_QUERY_NAME,
    DASHBOARD_MOBILE_STEP,
    DASHBOARD_MOBILE_STEP_NAME,
    ORDER_CONTAINER_IDS_QUERY_NAME,
} from '../constants';

const DashboardDocs = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const isMobile = useMobile();

    const counterparties = useSelector(getAllCounterparties);
    const documentGeneratorSettingList = useSelector(getDocumentGeneratorSettingList);

    const [selectedOrderContainerIds, setSelectedOrderContainerIds] = useQueryFilter<Array<string> | undefined>(
        ORDER_CONTAINER_IDS_QUERY_NAME,
        undefined,
        getArrayStringsFromQueryString,
        getQueryStringFromArrayString,
        false,
    );

    const [selectedCounterpartyId, setSelectedCounterpartyId] = useQueryFilter(
        COUNTERPARTY_ID_QUERY_NAME,
        counterparties[0]?.id,
    );
    const selectedCounterparty = counterparties.find((counterparty) => counterparty.id === selectedCounterpartyId);

    const [mobileStep, setMobileStep] = useQueryFilter<DASHBOARD_MOBILE_STEP | undefined>(
        DASHBOARD_MOBILE_STEP_NAME,
        DASHBOARD_MOBILE_STEP.TABLE,
        undefined,
        undefined,
        false,
    );

    const handleAddCounterparty = () => {
        navigate(BUSINESS_OFFICE_PATHS.organizations());
    };

    const orderContainerTable = (
        <OrderContainerTable
            addCounterparty={handleAddCounterparty}
            selectedRowIds={selectedOrderContainerIds}
            onSelectedRowIdsChange={setSelectedOrderContainerIds}
            selectedCounterparty={selectedCounterparty}
            setSelectedCounterpartyId={setSelectedCounterpartyId}
            counterparties={counterparties}
            documentGeneratorSettingList={documentGeneratorSettingList}
        />
    );

    const idDisabledReceiveDocuments = !selectedOrderContainerIds?.length || !selectedCounterpartyId;

    const documentReceiveMethods = (
        <S.DocumentReceiveMethodsWrapper>
            <DocumentReceiveMethods
                orderContainerIds={selectedOrderContainerIds}
                isDisabled={idDisabledReceiveDocuments}
                counterpartyId={selectedCounterpartyId}
            />
        </S.DocumentReceiveMethodsWrapper>
    );

    const mobileStepsMap = {
        table: {
            component: orderContainerTable,
            button: (
                <Button
                    key="next"
                    type="primary"
                    onClick={() => setMobileStep(DASHBOARD_MOBILE_STEP.RECEIVE_METHODS)}
                    size="large"
                    fullWidth
                    disabled={idDisabledReceiveDocuments}
                >
                    {intl.formatMessage({ id: 'Далее', defaultMessage: 'Далее' })}
                </Button>
            ),
        },
        receiveMethods: {
            component: documentReceiveMethods,
            button: (
                <Button
                    key="prev"
                    type="secondary"
                    onClick={() => setMobileStep(DASHBOARD_MOBILE_STEP.TABLE)}
                    size="large"
                    fullWidth
                >
                    {intl.formatMessage({ id: 'Назад', defaultMessage: 'Назад' })}
                </Button>
            ),
        },
    };

    const currentStepData = mobileStep ? mobileStepsMap[mobileStep] : undefined;

    return (
        <>
            <DocsHeaderContentPageWidget
                title={intl.formatMessage({
                    id: 'Запрос отчётных документов',
                    defaultMessage: 'Запрос отчётных документов',
                })}
            />

            {isMobile ? (
                <>
                    <S.MobileStepWrapper>{currentStepData?.component}</S.MobileStepWrapper>
                    <S.StepButtonWrapper>{currentStepData?.button}</S.StepButtonWrapper>
                </>
            ) : (
                <S.ContentWrapper>
                    {orderContainerTable}
                    {documentReceiveMethods}
                </S.ContentWrapper>
            )}
        </>
    );
};

export default DashboardDocs;
