import React, { useMemo } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import * as S from './styles';
import { TASKFLOW_CODE_FIELD_NAME, TTaskFlowCodeWithoutDownload } from '../../../store/TaskFlow/types';
import { getTaskFlowByCode } from '../../../store/TaskFlow/selectors';
import DocsHeaderContentPageWidget from '../../../components/DocsHeaderContentPageWidget';
import path from '../../../utils/path';
import { FormMethodReceiveDocumentContainer } from '../../../stories/docs/widgets/FormMethodReceiveDocumentContainer';
import { GET_DOCUMENT_PAGE_ROUTES } from './constants';
import RouteSuccessReceiveDocument from './components/RouteSuccessReceiveDocument';
import { getIsInitializedOrderContainerList, getOrderContainersByIds } from '../../../store/OrderContainer/selectors';
import { useQueryOrderContainerIds } from './hooks/useQueryOrderContainerIds';
import FormReceiveDocuments from './components/FormReceiveDocuments';
import { useData } from './hooks/useData';
import Loader from '../../../components/Loader';
import { useQueryCounterpartyId } from './hooks/useQueryCounterpartyId';
import { BUSINESS_OFFICE_PATHS } from '../../businessOffice/paths';
import {
    COUNTERPARTY_ID_QUERY_NAME,
    DASHBOARD_MOBILE_STEP,
    DASHBOARD_MOBILE_STEP_NAME,
    ORDER_CONTAINER_IDS_QUERY_NAME,
} from '../constants';
import OrderContainersTable from './components/OrderContainersTable';
import { DOCS_PATHS } from '../paths';
import { useLargeTablet, useMobile } from '../../../hooks/useMedia';
import { DATA_SPLITTER } from '../../../hooks/useQueryFilter';

const GetDocuments = () => {
    const { taskflowCode } = useParams() as { [TASKFLOW_CODE_FIELD_NAME]: TTaskFlowCodeWithoutDownload };
    const intl = useIntl();
    const isMobile = useMobile();
    const isLargeTablet = useLargeTablet();
    const navigate = useNavigate();
    const location = useLocation();

    const taskFlow = useSelector(getTaskFlowByCode(taskflowCode));

    const orderContainerIds = useQueryOrderContainerIds();
    const counterpartyId = useQueryCounterpartyId();
    const orderContainers = useSelector(getOrderContainersByIds(orderContainerIds));
    const isInitialized = useSelector(getIsInitializedOrderContainerList);

    const isSuccessRoute = location.pathname.includes(GET_DOCUMENT_PAGE_ROUTES.success);

    const serializedArray = orderContainerIds.join(DATA_SPLITTER);
    const queryParams = new URLSearchParams({
        [ORDER_CONTAINER_IDS_QUERY_NAME]: serializedArray,
        [COUNTERPARTY_ID_QUERY_NAME]: counterpartyId || '',
    });

    const isNeedFetchOrderContainers = useMemo(
        () => orderContainers.length !== orderContainerIds?.length,
        [orderContainerIds?.length, orderContainers.length],
    );

    useData(isNeedFetchOrderContainers, orderContainerIds);

    const handleGoToDashboard = () => {
        navigate(DOCS_PATHS.dashboard);
    };

    const handleBack = () => {
        queryParams.set(DASHBOARD_MOBILE_STEP_NAME, DASHBOARD_MOBILE_STEP.RECEIVE_METHODS);
        navigate({
            pathname: DOCS_PATHS.dashboard,
            search: queryParams.toString(),
        });
    };

    const handleGoToBusinessOffice = () => {
        navigate(BUSINESS_OFFICE_PATHS.dashboard());
    };

    const handleGoToSuccessPage = () => {
        navigate({
            pathname: path(DOCS_PATHS.getDocuments, taskflowCode, GET_DOCUMENT_PAGE_ROUTES.success),
            search: queryParams.toString(),
        });
    };

    if (!taskFlow) {
        // eslint-disable-next-line no-console
        console.error('not found taskflow');
        handleGoToDashboard();
        return null;
    }

    if (!isInitialized) {
        return <Loader />;
    }

    return (
        <S.Wrapper>
            <DocsHeaderContentPageWidget
                onBack={handleBack}
                title={intl.formatMessage({
                    id: 'Запрос отчётных документов',
                    defaultMessage: 'Запрос отчётных документов',
                })}
            />
            <S.ContentWrapper>
                {!(isLargeTablet && isSuccessRoute) && !isMobile && (
                    <S.OrderContainersTableWrapper>
                        <OrderContainersTable orderContainers={orderContainers} />
                    </S.OrderContainersTableWrapper>
                )}

                <FormMethodReceiveDocumentContainer variant={taskflowCode}>
                    <Routes>
                        <Route
                            path={GET_DOCUMENT_PAGE_ROUTES.success}
                            element={
                                <RouteSuccessReceiveDocument
                                    goToBusinessOffice={handleGoToBusinessOffice}
                                    goToDashboard={handleGoToDashboard}
                                    variant={taskflowCode}
                                    orderContainers={orderContainers}
                                />
                            }
                        />
                        <Route
                            path={GET_DOCUMENT_PAGE_ROUTES.form}
                            element={
                                <FormReceiveDocuments
                                    afterSubmit={handleGoToSuccessPage}
                                    onBack={handleBack}
                                    orderContainerIds={orderContainerIds}
                                    taskFlow={taskFlow}
                                    counterpartyId={counterpartyId}
                                />
                            }
                        />
                    </Routes>
                </FormMethodReceiveDocumentContainer>
            </S.ContentWrapper>
        </S.Wrapper>
    );
};

export default GetDocuments;
