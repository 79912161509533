import React, { FC } from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { Navbar, ProfileHeader, ProfileIconWrapper, ProfileTitle } from 'src/pages/businessOffice/Profile/styles';
import { ReactComponent as ProfileIcon } from 'src/assets/icons/profile.svg';
import { ShopNavLink } from 'src/pages/businessOffice/Shop/styles';
import { Navigate, Route, Routes } from 'react-router-dom';
import Personal from 'src/pages/businessOffice/Profile/ProfileTabs/Personal';
import Organizations from 'src/pages/businessOffice/Profile/ProfileTabs/Organizations';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BreadcrumbToDashboard } from '../../../components/BreadcrumbToDashboard';
import { BUSINESS_OFFICE_PATHS } from '../paths';
import { getCurrentCounterpartyId } from '../../../store/Counterparty/selectors';

export const Profile: FC = () => {
    const intl = useIntl();
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const profileNavigations = [
        {
            label: intl.formatMessage({ id: 'Пользователь', defaultMessage: 'Пользователь' }),
            to: BUSINESS_OFFICE_PATHS.personal(counterpartyId),
            path: RoutesBusinessOffice.personal,
            component: Personal,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Организации' }),
            to: BUSINESS_OFFICE_PATHS.organizations(counterpartyId),
            path: RoutesBusinessOffice.organizations,
            component: Organizations,
        },
    ];

    return (
        <>
            <Breadcrumb>
                <BreadcrumbToDashboard />
                <Breadcrumb.Item>{intl.formatMessage({ defaultMessage: 'Профиль пользователя' })}</Breadcrumb.Item>
            </Breadcrumb>
            <ProfileHeader>
                <ProfileIconWrapper>
                    <ProfileIcon />
                </ProfileIconWrapper>
                <ProfileTitle variant="bigTitle">
                    {intl.formatMessage({ defaultMessage: 'Профиль пользователя' })}
                </ProfileTitle>
            </ProfileHeader>
            <Navbar>
                {profileNavigations.map((navigationLink) => (
                    <ShopNavLink key={navigationLink.label} to={navigationLink.to}>
                        {navigationLink.label}
                    </ShopNavLink>
                ))}
            </Navbar>
            <Routes>
                {profileNavigations.map((route) => (
                    <Route key={route.label} path={route.path} Component={route.component} />
                ))}
                <Route path="*" element={<Navigate to={BUSINESS_OFFICE_PATHS.personal(counterpartyId)} />} />
            </Routes>
        </>
    );
};

export default Profile;
