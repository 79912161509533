import styled from 'styled-components';
import Typography from 'src/components/Typography';
import { Toggle } from 'src/components/Toggle';
import Checkbox, { CheckboxLabelText } from 'src/components/Checkbox';

export const RefundItemWrapper = styled.div`
    padding: 18px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    &:last-child {
        border: none;
        padding-bottom: 0;
    }
`;

export const TogglePartial = styled(Toggle)`
    @media ${({ theme }) => theme.breakpoints.xxl} {
        grid-column-start: 2;
        grid-column-end: 3;
        margin-right: 0;
        margin-left: auto;
    }
`;

export const FullRefundFields = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    @media ${({ theme }) => theme.breakpoints.xxl} {
        display: grid;
        grid-template-columns: 1fr 120px;
        grid-template-rows: repeat(2, 20px);
        grid-column-gap: 10px;
        grid-row-gap: 2px;
    }
`;

interface ITotalAmount {
    isActually: boolean;
}

export const TotalAmount = styled(Typography)<ITotalAmount>`
    max-width: 132px;
    width: 100%;
    text-align: right;
    color: ${({ theme, isActually }) => (isActually ? theme.colors.base() : theme.colors.base(0.5))};
    @media ${({ theme }) => theme.breakpoints.xxl} {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }
`;

export const CheckboxRefundItem = styled(Checkbox)`
    max-width: 207px;
    width: 100%;
    @media ${({ theme }) => theme.breakpoints.xxl} {
        max-width: 100%;
        width: auto;
    }

    ${CheckboxLabelText} {
        max-height: 32px;
        overflow: hidden;
    }
`;
