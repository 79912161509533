import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { thunkHandler } from 'src/utils/helpers';
import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';
import { TOrder, TOrderStatusUpdateProps } from './types';
import {
    cancelOrderRequest,
    createOrderRequest,
    getOrdersListRequest,
    updateOrderRequest,
    updateOrderStatusRequest,
} from './http';

export const ordersAdapter = createEntityAdapter<TOrder>();
const initialState = ordersAdapter.getInitialState({
    isLoading: true,
    isLoaded: false,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

export const fetchOrdersList = createAsyncThunkWithErrorHandler('orders/getList', async (params: {}) => {
    const { data } = await getOrdersListRequest(params);
    return data;
});

export const createOrder = createAsyncThunkWithErrorHandler<TOrder, TOrder>('order/create', async (params, thunkAPI) =>
    thunkHandler(createOrderRequest(params), thunkAPI),
);

export const updateOrder = createAsyncThunkWithErrorHandler<TOrder, TOrder>('order/update', async (data, thunkAPI) =>
    thunkHandler(updateOrderRequest(data), thunkAPI),
);

export const updateOrderStatus = createAsyncThunkWithErrorHandler<TOrder, TOrderStatusUpdateProps>(
    'order/updateStatus',
    async (data, thunkAPI) => thunkHandler(updateOrderStatusRequest(data), thunkAPI),
);
// @ts-ignore
export const cancelOrder = createAsyncThunkWithErrorHandler<TOrder, number>('order/cancel', async (id) => {
    const {
        data: { data },
    } = await cancelOrderRequest(id);
    return data;
});

const Orders = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setOrders(state, { payload }) {
            ordersAdapter.upsertMany(state, payload);
        },
        clearOrderList() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrdersList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchOrdersList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isLoaded = true;
            state.metaData = payload.metaData;
            ordersAdapter.setAll(state, payload.data);
        });
        builder.addCase(fetchOrdersList.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateOrder.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateOrder.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateOrder.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            ordersAdapter.updateOne(state, { id: payload.id, changes: payload });
        });
        builder.addCase(cancelOrder.fulfilled, (state, { payload }) => {
            ordersAdapter.updateOne(state, { id: payload.id, changes: payload });
        });
    },
});

export const { setOrders, clearOrderList } = Orders.actions;
export default Orders.reducer;
