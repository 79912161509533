import React, { FC, MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Arrow, Calendar, Dropdown, useOutsideClick } from '@invoicebox/ui';
import * as S from './styles';
import 'react-calendar/dist/Calendar.css';
import { convertDateToShow } from '../../../utils/helpers';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';

export type TProps = { value: null | [Date, Date]; onChange: (value: [Date, Date] | null) => void };

export const DateRangePicker: FC<TProps> = ({ value, onChange }) => {
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => setIsOpen(false);
    const toggle = () => setIsOpen((prev) => !prev);

    const wrapperRef = useOutsideClick(handleClose);

    const handleChange = (newValue: [Date, Date]) => {
        onChange(newValue);
        handleClose();
    };

    const handleResetValue = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onChange(null);
    };

    return (
        <S.Wrapper ref={wrapperRef}>
            <S.Content type="button" onClick={toggle}>
                <S.Label variant="smallMedium">
                    {value
                        ? `${convertDateToShow(value[0])} - ${convertDateToShow(value[1])}`
                        : intl.formatMessage({
                              id: 'Выбрать период',
                              defaultMessage: 'Выбрать период',
                          })}
                </S.Label>
                {value ? (
                    <S.ResetButton onClick={handleResetValue}>
                        <CrossIcon />
                    </S.ResetButton>
                ) : (
                    <Arrow isOpen={isOpen} outterSize={18} />
                )}
            </S.Content>
            <Dropdown isOpen={isOpen} positionLeft="auto" positionRight="0px">
                <S.CalendarWrapper>
                    <Calendar onChange={handleChange} value={value} selectRange />
                </S.CalendarWrapper>
            </Dropdown>
        </S.Wrapper>
    );
};
