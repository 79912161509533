import styled from 'styled-components';
import Typography from 'src/components/Typography';
import { NavLink } from 'react-router-dom';

export const Title = styled(Typography)`
    margin-bottom: 26px;
`;

export const FeedList = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.large}px;
    border: 1px solid ${({ theme }) => theme.colors.grey200()};
    overflow: hidden;
`;

export const MoreNavLink = styled(NavLink)`
    color: ${({ theme }) => theme.colors.grey()};
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    text-decoration: none;
    margin-top: 17px;
`;
