import styled from 'styled-components';

export const Head = styled.div`
    padding: 12px 16px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
`;

export const LogoWrapper = styled.div`
    padding: 10px 16px;
    opacity: 0.6;
`;

export const CloseButton = styled.button`
    height: 44px;
    width: 44px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.grey200()};
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;
