import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    background: ${({ theme }) => theme.colors.grey200()};
    padding: 8px 16px;
    flex: 1;
`;

export const MenuItemsWrapper = styled.div`
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.white()};
`;

export const MenuItemWrapper = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.base(0.1)};

    &:last-child {
        border-bottom: none;
    }
`;

export const MenuItemContentWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 16px;
    padding: 8px 16px;
    width: 100%;
    height: 56px;
`;

export const MenuItemIconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-shrink: 0;
    background: ${({ theme }) => theme.colors.grey200()};
`;

export const MenuItemLabel = styled(Typography)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const ArrowWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 0;
`;
