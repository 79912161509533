import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const IconWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-shrink: 0;
`;

export const Wrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 8px;
    padding: 8px;
    margin: 0 12px;
    cursor: pointer;
    overflow: hidden;

    &:hover {
        background-color: ${({ theme }) => theme.colors.wrongGrey100()};
        border-radius: 12px;
    }
`;

export const MenuItemRightIconWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    margin-left: auto;
    margin-right: 0;
    flex-shrink: 0;
`;

export const Label = styled(Typography)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
