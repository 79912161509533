import React from 'react';

export const OrganizationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.52645 20.0089H15.483C18.0382 20.0089 19.9986 19.0859 19.4418 15.3712L18.7934 10.3369C18.4501 8.48331 17.2679 7.77393 16.2305 7.77393H7.74842C6.69578 7.77393 5.58213 8.53671 5.18548 10.3369L4.53712 15.3712C4.06421 18.6664 5.97115 20.0089 8.52645 20.0089Z"
            stroke="#353F47"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.4023 11.3442H14.4405"
            stroke="#353F47"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.54297 11.3442H9.58111"
            stroke="#353F47"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.41406 7.59106C8.41406 5.60267 10.026 3.99076 12.0143 3.99076C12.9718 3.9867 13.8915 4.36422 14.57 5.03986C15.2485 5.71548 15.6299 6.63356 15.6299 7.59106"
            stroke="#353F47"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
