import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { TCountry } from 'src/store/Country/types';
import { fetchCountryListRequest } from 'src/store/Country/http';
import { Country } from './entity';

export const fetchCountryList = createAsyncThunk<Array<TCountry>, void>('country/fetchAll', async () => {
    const { data } = await fetchCountryListRequest();
    return data.data;
});

export const countryAdapter = createEntityAdapter<Country>();

const initialState = countryAdapter.getInitialState({
    isLoading: false,
    isInitialized: false,
});

const country = createSlice({
    name: 'country',
    initialState,
    reducers: {
        clearCountryList() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCountryList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCountryList.fulfilled, (state, action) => {
            state.isInitialized = true;
            state.isLoading = false;
            countryAdapter.setAll(
                state as EntityState<Country>,
                action.payload.map((countryFromResponse) => Country.fromResponse(countryFromResponse)),
            );
        });
        builder.addCase(fetchCountryList.rejected, (state) => {
            state.isLoading = false;
            state.isInitialized = true;
        });
    },
});

export const { clearCountryList } = country.actions;

export default country.reducer;
