import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { CodeInput, useLoadingSubmit } from '@invoicebox/ui';
import Subtitle from '../Subtitle';
import * as S from './styles';
import Button from '../../../../../stories/common/Button';
import { useMobile } from '../../../../../hooks/useMedia';
import { NewCodeCounterLabel } from './styles';
import { SMS_CODE_LENGTH } from '../../../../../utils/constants';
import { getNoun } from '../../../../../utils/helpers';

type TProps = {
    timer: number;
    onGetNewCode: () => Promise<void>;
    onSubmit: (codeValue: string) => Promise<any>;
    isCodeError: boolean;
    isDisabled: boolean;
    isCanAgainSendCode: boolean;
    leftAttempts: number;
};

const CodeEnterStep: FC<TProps> = ({
    timer,
    onGetNewCode,
    onSubmit,
    isCodeError,
    leftAttempts,
    isCanAgainSendCode,
    isDisabled,
}) => {
    const intl = useIntl();
    const isMobile = useMobile();

    const [code, setCode] = useState('');
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const handleCodeChange = async (value: string) => {
        setCode(value);

        if (value.length === SMS_CODE_LENGTH) {
            toggleLoadingSubmit();
            const result = await onSubmit(value);

            if (result?.error) {
                setCode('');
            }
            toggleLoadingSubmit();
        }
    };

    return (
        <>
            <Subtitle
                steps="1/2"
                label={intl.formatMessage({
                    id: 'На ваш номер мобильного телефона отправлен SMS-код. Для авторизации, пожалуйста, укажите полученный код',
                    defaultMessage:
                        'На ваш номер мобильного телефона отправлен SMS-код. Для авторизации, пожалуйста, укажите полученный код',
                })}
            />
            <S.Wrapper>
                <CodeInput
                    autoFocus
                    disabled={isDisabled}
                    value={code}
                    onChange={handleCodeChange}
                    codeLength={SMS_CODE_LENGTH}
                    hasError={isCodeError}
                />
                <S.NoteWrapper>
                    {isCodeError ? (
                        <S.WrongLabel variant="bodyMRegular">
                            {leftAttempts > 0
                                ? `${intl.formatMessage({
                                      id: 'Код неверный (осталось',
                                      defaultMessage: 'Код неверный (осталось',
                                  })} ${leftAttempts} ${getNoun(
                                      leftAttempts,
                                      intl.formatMessage({
                                          id: 'попытка',
                                          defaultMessage: 'попытка',
                                      }),
                                      intl.formatMessage({
                                          id: 'попытки',
                                          defaultMessage: 'попытки',
                                      }),
                                      intl.formatMessage({
                                          id: 'попыток',
                                          defaultMessage: 'попыток',
                                      }),
                                  )})`
                                : intl.formatMessage({
                                      id: 'Запросите новый код',
                                      defaultMessage: 'Запросите новый код',
                                  })}
                        </S.WrongLabel>
                    ) : (
                        <S.DontGetCodeLabel variant="bodyMRegular">
                            {intl.formatMessage({ id: 'Не получили код?', defaultMessage: 'Не получили код?' })}
                        </S.DontGetCodeLabel>
                    )}
                    {isCanAgainSendCode ? (
                        <button type="button" onClick={onGetNewCode}>
                            <S.SendCodeAgainLabel variant="buttonM">
                                {intl.formatMessage({ defaultMessage: 'Отправить код повторно' })}
                            </S.SendCodeAgainLabel>
                        </button>
                    ) : (
                        <NewCodeCounterLabel variant="buttonM">
                            {intl.formatMessage(
                                { defaultMessage: 'Отправить повторно через {seconds} сек' },
                                {
                                    seconds: timer,
                                },
                            )}
                        </NewCodeCounterLabel>
                    )}
                </S.NoteWrapper>
                <Button
                    isLoading={isLoadingSubmit}
                    fullWidth={isMobile}
                    disabled={code.length !== SMS_CODE_LENGTH}
                    size="large"
                >
                    {intl.formatMessage({ defaultMessage: 'Продолжить' })}
                </Button>
            </S.Wrapper>
        </>
    );
};

export default CodeEnterStep;
