import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Button from 'src/stories/common/Button';

export const IntegrationsWrapper = styled.div`
    padding-top: 30px;
    width: 100%;
`;

export const IntegrationsHeader = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 37px;
    margin-bottom: 30px;
`;

export const HeaderDescription = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    margin-top: 4px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: none;
    }
`;

export const ButtonAddNewIntegration = styled(Button)`
    svg {
        path {
            stroke: ${({ theme }) => theme.colors.base()};
        }
    }
    ${({ theme }) => theme.helpers.getTypography('bold14')};
    white-space: nowrap;
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
    }
`;

export const IntegrationsListWrapper = styled.div`
    padding: 30px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 20px;
    }
`;

export const IntegrationsListTitle = styled(Typography)`
    margin-bottom: 34px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 30px;
    }
`;

export const IntegrationsList = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.grey200()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
`;

export const IntegrationApiLink = styled.a``;
