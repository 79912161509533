import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 12px 16px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
`;

export const UserButtonWrapper = styled.button`
    width: 44px;
    height: 44px;
    border-radius: 50px;
    background: ${({ theme }) => theme.colors.grey200()};
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;

export const ActionsWrapper = styled.div`
    padding: 10px;
    border-radius: 50px;
    background: ${({ theme }) => theme.colors.grey200()};
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 10px;
`;

export const ActionDivider = styled.div`
    width: 1px;
    height: 16px;
    background: ${({ theme }) => theme.colors.base(0.04)};
`;
