import React, { FC } from 'react';
import { useOutsideClick } from '@invoicebox/ui';
import Portal from '../../../components/Portal';
import * as S from './styles';

export type TProps = {
    onClose: () => void;
    isOpen: boolean;
};

export const Modal: FC<TProps> = ({ onClose, isOpen, children }) => {
    const contentRef = useOutsideClick(onClose);

    if (!isOpen) return null;

    return (
        <Portal>
            <S.OverflowHidden />
            <S.Container>
                <S.ContainerInner>
                    <S.Backdrop />
                    <S.Content ref={contentRef}>{children}</S.Content>
                </S.ContainerInner>
            </S.Container>
        </Portal>
    );
};
