import { RootState } from '../index';
import { taskAdapter } from './index';

const getTasks = (state: RootState) => state.task;

const { selectAll } = taskAdapter.getSelectors(getTasks);

export const getTaskList = (state: RootState) => selectAll(state);
export const getTaskInitialized = (state: RootState) => getTasks(state).isInitialized;
export const getTaskIsLoading = (state: RootState) => getTasks(state).isLoading;
