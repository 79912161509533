import { useState } from 'react';
import { useSelector } from 'react-redux';
import { verificationByCode, verificationByPhone } from '../store/Auth';
import { phoneFormatter, removePlusFromPhone } from '../utils/formatters';
import { useAppDispatch } from '../store';
import { useReCaptchaTokenContext } from '../context/ReCaptchaTokenContext';
import { getLeftAttempts } from '../store/Auth/selectors';
import { useExpiredEnterSmsCode } from '../stories/auth/AuthWidget/hooks/useExpiredEnterSmsCode';

type TParams = {
    goToSmsStep: () => void;
};

export const useAuth = ({ goToSmsStep }: TParams) => {
    const dispatch = useAppDispatch();
    const { token, onRefreshReCaptcha } = useReCaptchaTokenContext();

    const leftAttempts = useSelector(getLeftAttempts) as number;

    const { isExpired, timer } = useExpiredEnterSmsCode();

    const isCanAgainSendCode = timer === 0;

    const [phone, setPhone] = useState('');
    const [isCodeError, setIsCodeError] = useState(false);

    const sendCodeByPhone = async (curPhone: string) => {
        const data = await dispatch(
            verificationByPhone({
                phone: phoneFormatter(removePlusFromPhone(curPhone)),
                token,
            }),
        );
        onRefreshReCaptcha();
        return data;
    };

    const authByPhone = async (curPhone: string) => {
        const data = await sendCodeByPhone(curPhone);

        if (!('error' in data) && data?.payload) {
            goToSmsStep();
            setPhone(curPhone);
        }
    };

    const sendCode = async (code: string) => {
        const result = await dispatch(verificationByCode({ code, token }));
        onRefreshReCaptcha();

        if ('error' in result) {
            setIsCodeError(true);
        }

        return result;
    };

    const handleResendCode = () => sendCodeByPhone(phone).then(() => setIsCodeError(false));

    return {
        handleResendCode,
        sendCode,
        authByPhone,
        isCodeError,
        isExpired,
        timer,
        leftAttempts,
        phone,
        isCanAgainSendCode,
    };
};
