import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';
import { nameToInitialsFormatter } from '../../../../utils/formatters';

export type TProps = {
    name: string;
};

export const NameCircle: FC<TProps> = ({ name }) => (
    <S.Wrapper>
        <Typography variant="captionRegular">{nameToInitialsFormatter(name)}</Typography>
    </S.Wrapper>
);
