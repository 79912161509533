import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 12px;
`;

export const IconActionsWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
`;

export const UserButtonWrapper = styled.div`
    position: relative;
`;

export const UserButton = styled.button`
    padding: 8px 12px 8px 16px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    gap: 10px;
    background-color: ${({ theme }) => theme.colors.wrongGrey100()};
    border-radius: 16px;
    width: 178px;
    text-align: left;
`;

export const UserData = styled.div`
    overflow: hidden;
`;

export const Subtitle = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey700()};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Title = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
