import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const ClientName = styled(Typography)`
    text-align: center;
`;

export const LabelAbsentClients = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    margin: auto 0;
`;

export const ButtonLastClient = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}
    flex-direction: column;
    width: 77px;
`;

export const LastClientsContainer = styled.div`
    ${({ theme }) => theme.mixins.flexStart}
    grid-column-gap: 12px;
    padding-bottom: 14px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
`;
