import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Arrow, Typography } from '@invoicebox/ui';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { ReactComponent as MainIcon } from './assets/mail.svg';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ICounterparty } from '../../../../store/Counterparty/types';
import { OrganizationIcon } from '../OrganizationIcon';
import { UserIcon } from '../UserIcon';

export type TProps = {
    userName: string;
    identifier: string;
    onOrganizationSelect: (counterpartyId?: string) => void;
    onProfileGoTo: () => void;
    onLogout: () => void;
    selectedCounterparty?: ICounterparty;
    counterparties?: Array<ICounterparty>;
};

export const UserSectionMenuMobile: FC<TProps> = ({
    selectedCounterparty,
    counterparties,
    userName,
    identifier,
    onOrganizationSelect,
    onProfileGoTo,
    onLogout,
}) => {
    const intl = useIntl();
    const theme = useTheme();

    return (
        <S.Wrapper>
            <S.ProfileButton type="button" onClick={onProfileGoTo}>
                <S.ProfileIconAndTitle>
                    <MainIcon />
                    <Typography variant="headline4">
                        {intl.formatMessage({ id: 'Профиль', defaultMessage: 'Профиль' })}
                    </Typography>
                </S.ProfileIconAndTitle>
                <Arrow palette={{ open: theme.colors.base() }} isOpen defaultRotate={90} />
            </S.ProfileButton>

            <S.CounterpartiesWrapper>
                <S.CounterpartyButton
                    key="user"
                    type="button"
                    $isActive={!selectedCounterparty}
                    onClick={() => onOrganizationSelect()}
                >
                    <S.CounterpartyAvatarWrapper>
                        <UserIcon />
                    </S.CounterpartyAvatarWrapper>
                    <S.CounterpartyData>
                        <S.CounterpartyTitle variant="headline4">{userName}</S.CounterpartyTitle>
                        <S.CounterpartySubTitle variant="captionRegular">{identifier}</S.CounterpartySubTitle>
                    </S.CounterpartyData>
                    <S.CounterpartyTags>
                        <S.UserLabel variant="captionRegular">
                            {intl.formatMessage({ id: 'ФИЗ', defaultMessage: 'ФИЗ' })}
                        </S.UserLabel>
                        {!selectedCounterparty && <CheckIcon />}
                    </S.CounterpartyTags>
                </S.CounterpartyButton>
                {counterparties?.map((counterparty) => (
                    <S.CounterpartyButton
                        key={counterparty.id}
                        type="button"
                        $isActive={counterparty.id === selectedCounterparty?.id}
                        onClick={() => onOrganizationSelect(counterparty.id)}
                    >
                        <S.CounterpartyAvatarWrapper>
                            <OrganizationIcon />
                        </S.CounterpartyAvatarWrapper>
                        <S.CounterpartyData>
                            <S.CounterpartyTitle variant="headline4">{counterparty.name}</S.CounterpartyTitle>
                            <S.CounterpartySubTitle variant="captionRegular">
                                {intl.formatMessage({ id: 'ИНН:', defaultMessage: 'ИНН:' })} {counterparty.vatNumber}
                            </S.CounterpartySubTitle>
                        </S.CounterpartyData>
                        <S.CounterpartyTags>
                            <S.CounterpartyLabel variant="captionRegular">
                                {intl.formatMessage({ id: 'ЮР', defaultMessage: 'ЮР' })}
                            </S.CounterpartyLabel>
                            {counterparty.id === selectedCounterparty?.id && <CheckIcon />}
                        </S.CounterpartyTags>
                    </S.CounterpartyButton>
                ))}
            </S.CounterpartiesWrapper>
            <S.LogoutButton type="button" onClick={onLogout}>
                <Typography variant="buttonM">
                    {intl.formatMessage({ id: 'Выйти из профиля', defaultMessage: 'Выйти из профиля' })}
                </Typography>
            </S.LogoutButton>
        </S.Wrapper>
    );
};
