import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 12px 16px;
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 12px;
    background: ${({ theme }) => theme.colors.grey200()};
`;

export const BackButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    padding: 10px;
`;
