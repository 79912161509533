import React, { FC, useState } from 'react';
import { PhoneInput, useLoadingSubmit } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import Subtitle from '../Subtitle';
import * as S from './styles';
import Button from '../../../../../stories/common/Button';
import { useMobile } from '../../../../../hooks/useMedia';

type TProps = {
    authByPhone: (phone: string) => Promise<void>;
};

const PhoneEnterStep: FC<TProps> = ({ authByPhone }) => {
    const intl = useIntl();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const [phone, setPhone] = useState<string>('');
    const isMobile = useMobile();

    const handleSendCode = async () => {
        toggleLoadingSubmit();
        const data = await authByPhone(phone);

        toggleLoadingSubmit();

        return data;
    };

    return (
        <>
            <Subtitle
                steps="1/2"
                label={intl.formatMessage({
                    id: 'Для получения отчётных документов, пожалуйста, авторизуйтесь в системе Инвойсбокс',
                    defaultMessage: 'Для получения отчётных документов, пожалуйста, авторизуйтесь в системе Инвойсбокс',
                })}
            />
            <S.Wrapper>
                <PhoneInput
                    label={intl.formatMessage({ defaultMessage: 'Номер телефона' })}
                    countrySelectProps={{
                        selectedLabel: '',
                        placeholder: '',
                    }}
                    value={phone}
                    onChange={setPhone}
                />
                <Button
                    isLoading={isLoadingSubmit}
                    fullWidth={isMobile}
                    disabled={!phone}
                    onClick={handleSendCode}
                    size="large"
                >
                    {intl.formatMessage({ defaultMessage: 'Отправить код' })}
                </Button>
            </S.Wrapper>
        </>
    );
};

export default PhoneEnterStep;
