import React, { FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from 'src/layouts/Main';
import AuthDocs from 'src/pages/docs/Auth';
import {
    APP_VARIANT_ROUTES,
    PARAM_COUNTERPARTY_ID_NAME,
    RoutesBusinessOffice,
    RoutesDocs,
    TAuthTypeFlow,
} from 'src/pages/constants';
import Dashboard from 'src/pages/businessOffice/Dashboard';
import Introduce from 'src/pages/businessOffice/Introduce';
import Shop from 'src/pages/businessOffice/Shop';
import Payment from 'src/pages/businessOffice/Payment';
import OrderContainer from 'src/pages/businessOffice/OrderContainer';
import Profile from 'src/pages/businessOffice/Profile';
import Counterparty from 'src/pages/businessOffice/Counterparty';
import Feeds from 'src/pages/businessOffice/Feeds';
import Claim from 'src/pages/businessOffice/Claim';
import Refund from 'src/pages/businessOffice/Refund';
import { useSelector } from 'react-redux';
import DashboardDocs from './docs/Dashboard';
import Statuses from './docs/Statuses';
import DocsAuthLayout from '../components/DocsPageContainer';
import GetDocuments from './docs/GetDocuments';
import path from '../utils/path';
import { TASKFLOW_CODE_FIELD_NAME } from '../store/TaskFlow/types';
import { fetchCountryList } from '../store/Country';
import { useAppDispatch } from '../store';
import { loadDictionary } from '../store/Dictionaries';
import { DICTIONARY_TYPES } from '../store/Dictionaries/constants';
import { getIsInitializedDictionaries } from '../store/Dictionaries/selectors';
import { BUSINESS_OFFICE_PATHS } from './businessOffice/paths';
import { DOCS_PATHS } from './docs/paths';

type TProps = {
    isNeedIntroduce: boolean;
    authTypeFlow?: TAuthTypeFlow;
};

export const BusinessOfficeAuthRouter: FC<TProps> = ({ isNeedIntroduce, authTypeFlow }) => {
    const dispatch = useAppDispatch();

    const isInitializedDictionaries = useSelector(getIsInitializedDictionaries);

    useEffect(() => {
        if (isInitializedDictionaries) {
            dispatch(loadDictionary(DICTIONARY_TYPES.legalEntityType));
            dispatch(loadDictionary(DICTIONARY_TYPES.mcc));
        }
    }, [dispatch, isInitializedDictionaries]);

    useEffect(() => {
        dispatch(fetchCountryList());
    }, [dispatch]);

    if (isNeedIntroduce) {
        return (
            <Routes>
                <Route path="" Component={Introduce} />
                {authTypeFlow ? (
                    <Route path="*" element={<Navigate to={BUSINESS_OFFICE_PATHS.introduce(authTypeFlow)} />} />
                ) : (
                    <Route path="*" element={<Navigate to={BUSINESS_OFFICE_PATHS.introduce()} />} />
                )}
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path={path(`:${PARAM_COUNTERPARTY_ID_NAME}`, '/*')} Component={MainRoutes} />
            <Route path="*" element={<Navigate to={BUSINESS_OFFICE_PATHS.dashboard()} />} />
        </Routes>
    );
};

const MainRoutes = () => (
    <MainLayout>
        <Routes>
            <Route path={path(RoutesBusinessOffice.profile, '/*')} Component={Profile} />
            <Route path={RoutesBusinessOffice.dashboard} Component={Dashboard} />
            <Route path={path(RoutesBusinessOffice.counterparty, ':counterpartyId')} Component={Counterparty} />
            <Route path={RoutesBusinessOffice.payment} Component={Payment} />
            <Route
                path={path(RoutesBusinessOffice.orderContainer, ':orderContainerId', RoutesBusinessOffice.refundCreate)}
                Component={Refund}
            />
            <Route path={path(RoutesBusinessOffice.orderContainer, ':orderContainerId')} Component={OrderContainer} />
            <Route path={path(RoutesBusinessOffice.shop, ':merchantId', '/*')} Component={Shop} />
            <Route path={RoutesBusinessOffice.feeds} Component={Feeds} />
            {/* роут для случаев, когда переход по нажатию на фид в фоне */}
            <Route path={path(RoutesBusinessOffice.feeds, ':pushFeedId')} Component={Feeds} />

            {/* когда переход на страницу создания заявки со страницы мерчанта */}
            <Route path={path(RoutesBusinessOffice.claim, ':merchantId')} Component={Claim} />
            <Route path={path(RoutesBusinessOffice.claim)} Component={Claim} />
        </Routes>
    </MainLayout>
);

export const BusinessOfficeNotAuthRouter = () => <Navigate to={APP_VARIANT_ROUTES.auth} />;

export const DocsNotAuthRouter = () => (
    <Routes>
        <Route path={RoutesDocs.main} element={<AuthDocs initialStep="auth" />} />
        <Route path="*" element={<Navigate to={DOCS_PATHS.main} />} />
    </Routes>
);

export const DocsAuthRouter = () => (
    <Routes>
        <Route path={RoutesDocs.main} element={<AuthDocs initialStep="requisite" />} />
        <Route path="/*" element={<DocsRoutesWithLayout />} />
        <Route path="*" element={<Navigate to={DOCS_PATHS.dashboard} />} />
    </Routes>
);

const DocsRoutesWithLayout = () => (
    <DocsAuthLayout>
        <Routes>
            <Route path={RoutesDocs.dashboard} element={<DashboardDocs />} />
            <Route path={RoutesDocs.statuses} element={<Statuses />} />
            <Route
                path={path(RoutesDocs.getDocuments, `:${TASKFLOW_CODE_FIELD_NAME}`, '/*')}
                element={<GetDocuments />}
            />
            <Route path="*" element={<Navigate to={DOCS_PATHS.dashboard} />} />
        </Routes>
    </DocsAuthLayout>
);
