import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';
import bgDesktopImage from './assets/bg-desktop.png';
import bgMobileImage from './assets/bg-mobile.png';

export const ModalInner = styled.div`
    width: 550px;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;

export const Header = styled.div`
    height: 171px;
    background: url(${bgDesktopImage}) no-repeat;
    background-size: 100% 100%;

    @media ${({ theme }) => theme.breakpoints.md} {
        height: 265px;
        background:
            url(${bgMobileImage}) no-repeat center,
            #e0ebf7;
        background-size: auto 100%;
    }
`;

export const Content = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 16px;
        gap: 40px;
    }
`;

export const Description = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey900()};
`;

export const Texts = styled.div`
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: flex;
        flex-direction: column;
        gap: 16px;
        text-align: center;
    }
`;
