import styled from 'styled-components';

export const MenuWrapper = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 8;
`;

export const DrawerButton = styled.button`
    padding: 16px 20px;
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 10px;
`;
