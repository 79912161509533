import styled from 'styled-components';
import Skeleton from 'src/components/Skeleton';

export const SkeletonItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr 4fr 5fr 3fr 3fr;
    grid-column-gap: 30px;
    padding: 30px;

    :not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 1fr 4fr 4fr;
        padding: 30px 20px;
    }
`;

export const SkeletonValue = styled(Skeleton)`
    height: 16px;
`;
