import React, { FC, ReactNode, useEffect } from 'react';
import * as S from './styles';

export type TProps = {
    isOpen: boolean;
    header: ReactNode;
};

export const DrawerMenuContainer: FC<TProps> = ({ isOpen, children, header }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('disable-scroll');
        } else {
            document.body.classList.remove('disable-scroll');
        }

        return () => {
            document.body.classList.remove('disable-scroll');
        };
    }, [isOpen]);

    return (
        <S.Wrapper $isOpen={isOpen}>
            {header}
            {children}
        </S.Wrapper>
    );
};
