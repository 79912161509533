import styled, { createGlobalStyle } from 'styled-components';

export const OverflowHidden = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
`;

export const ContainerInner = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden auto;
    align-items: flex-start;
    padding: 55px 0;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 15px 0;
    }
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.darkest(0.5)};
`;

export const Content = styled.div`
    margin: auto;
    max-width: calc(100vw - 30px);
    overflow: auto;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white()};
    border-radius: 20px;
    box-shadow: 0 10px 30px 0 rgba(53, 63, 71, 0.1);

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;
