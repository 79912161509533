import styled from 'styled-components';
import Modal from 'src/components/Modal';
import { ReactComponent as OrdersIcon } from 'src/assets/icons/order.svg';
import ColumnField from 'src/pages/businessOffice/Shop/ShopTabsTables/RefundDetail/components/ColumnField';

export const ModalOrderDetailWrapper = styled(Modal)`
    width: 642px;
    padding: 0;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;

export const ModalHeader = styled.div`
    background: ${({ theme }) => theme.colors.grey200()};
    padding: 40px 50px;
    height: 100px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 30px 50px 30px 20px;
        height: auto;
    }
`;

export const OrderStatus = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 6px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    color: ${(props) => props.color};
`;

export const ModalContent = styled.div`
    padding: 40px 50px 50px 50px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 30px 20px 40px 20px;
    }
`;

export const OrderIcon = styled(OrdersIcon)`
    margin: auto;
`;

export const WrapperIcon = styled.div`
    position: relative;
`;

export const WrapStatusIcon = styled.div`
    display: flex;
    position: absolute;
    bottom: 9px;
    right: 9px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.grey200()};
`;

export const GeneralData = styled.div``;

export const GeneralFields = styled.div`
    padding: 21px 10px 41px 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 32px;
    grid-column-gap: 10px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 17px 10px 30px 10px;
    }
`;
export const Description = styled(ColumnField)`
    grid-column-start: 1;
    grid-column-end: 3;
`;

export const CompositionOrder = styled.div``;

export const OrderBasketItems = styled.div`
    margin-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.grey200()};
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-top: 20px;
    }
`;
