import http, { ICommonResponse } from '../../utils/http';
import { TTaskFlow } from './types';

export const getTaskFlowListRequest = () =>
    http.get<ICommonResponse<Array<TTaskFlow>>>('/document/paymentgateway/taskflow/taskflow');

export const setCounterpartyAccess = (params: {
    vatNumber: string;
    invoiceNumber: string;
    paymentOrderNumber: string;
}) =>
    http.post<ICommonResponse<Array<TTaskFlow>>>('/document/paymentgateway/taskflow/counterparty-access', {
        ...params,
    });
