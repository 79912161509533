import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const OrderItemWrapper = styled.div`
    padding: 18px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    display: grid;
    grid-template-columns: 1fr 110px;
    align-items: center;
`;

export const OrderItemName = styled(Typography)``;

export const QuantityAmount = styled(Typography)`
    text-align: right;
`;
