import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MenuItemButton = styled.button`
    width: 100%;
`;

export const MenuItemLink = styled(Link)`
    text-decoration: none;
`;
