import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { thunkHandler } from 'src/utils/helpers';
import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';
import {
    createClientCounterpartyRequest,
    getClientCounterpartyRequest,
    removeClientCounterpartyRequest,
    updateClientCounterpartyRequest,
} from './http';
import { IClientCounterparty, TCounterpartyClientRequestFields } from './types';

export const createClientCounterparty = createAsyncThunkWithErrorHandler(
    'clientCounterparty/clientCounterpartyCreate',
    async (data: TCounterpartyClientRequestFields, thunkAPI) =>
        thunkHandler(createClientCounterpartyRequest(data), thunkAPI),
);

export const getClientCounterparty = createAsyncThunkWithErrorHandler(
    'clientCounterparty/getClientCounterpartyList',
    async (data, thunkAPI) => thunkHandler(getClientCounterpartyRequest(), thunkAPI),
);

export const updateClientCounterparty = createAsyncThunkWithErrorHandler(
    'clientCounterparty/clientCounterpartyUpdate',
    async (data: any, thunkApi) => thunkHandler(updateClientCounterpartyRequest(data), thunkApi),
);

export const removeClientCounterparty = createAsyncThunkWithErrorHandler(
    'clientCounterparty/clientCounterpartyRemove',
    async (id: string) => {
        await removeClientCounterpartyRequest(id);
        return id;
    },
);

export const clientCounterpartyAdapter = createEntityAdapter<IClientCounterparty>();
const initialState = clientCounterpartyAdapter.getInitialState<{ isLoading: boolean }>({
    isLoading: false,
});

const ClientCounterparty = createSlice({
    name: 'clientCounterparty',
    initialState,
    reducers: {
        clearClientCounterparties() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createClientCounterparty.fulfilled, (state, action) => {
            clientCounterpartyAdapter.addOne(state, action.payload);
        });
        builder.addCase(getClientCounterparty.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getClientCounterparty.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getClientCounterparty.fulfilled, (state, action) => {
            state.isLoading = false;
            clientCounterpartyAdapter.addMany(state, action.payload);
        });
        builder.addCase(updateClientCounterparty.fulfilled, (state, action) => {
            clientCounterpartyAdapter.upsertOne(state, action.payload);
        });
        builder.addCase(removeClientCounterparty.fulfilled, (state, action) => {
            clientCounterpartyAdapter.removeOne(state, action.payload);
        });
    },
});

export const { clearClientCounterparties } = ClientCounterparty.actions;
export default ClientCounterparty.reducer;
