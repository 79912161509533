import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Modal from 'src/components/Modal';

export const ContainerOrderToPay = styled.div`
    padding: 40px 0;
    width: 100%;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 40px 0 0;
    }
`;

export const TitleOrderToPay = styled(Typography)`
    margin-bottom: 43px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('subheading')};
    }
`;

export const ContentOrderToPay = styled.div`
    display: grid;
    grid-template-columns: 1fr 261px;
    grid-column-gap: 30px;
    align-items: flex-start;
    @media ${({ theme }) => theme.breakpoints.xl} {
        grid-template-columns: 100%;
        grid-row-gap: 30px;
    }
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-row-gap: 105px;
    }
`;

export const ButtonAddOrderItemWrapper = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 6px;
    position: absolute;
    z-index: 2;
    right: 73px;
    top: 30px;
`;

export const LabelButtonAddOrderItem = styled(Typography)``;

export const FormCreateOrder = styled.form`
    display: contents;
`;

export const ButtonClientModalFindClient = styled.button`
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    padding: 20px 15px 20px 16px;
    text-align: left;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.colors.grey200()};
`;

export const ClientsList = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.grey200()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
`;

export const ContentModalFindClient = styled.div`
    margin-right: -30px;
    padding-right: 28px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.38);
    }
    scrollbar-color: rgba(0, 0, 0, 0.38);
    scrollbar-width: thin;

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-right: 0;
        padding-right: 0;
    }
`;

export const HeaderModalFindClient = styled.div`
    padding: 42px 50px 27px 50px;
    background: ${({ theme }) => theme.colors.white()};
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 26px 50px 16px 20px;
    }
`;

interface IHeadLetterModalFindClient {
    isFirstLetter?: boolean;
}

export const HeadLetterModalFindClient = styled.div<IHeadLetterModalFindClient>`
    color: ${({ theme }) => theme.colors.base()};
    background: ${({ theme }) => theme.colors.grey200()};
    padding: 4px 16px;
    border-radius: ${({ isFirstLetter, theme }) =>
        isFirstLetter ? `${theme.decorations.borderRadius.base}px ${theme.decorations.borderRadius.base}px 0 0` : '0'};
    @media ${({ theme }) => theme.breakpoints.sm} {
        border-radius: 0;
    }
`;

export const ModalFindClientWrapper = styled(Modal)`
    width: 474px;
    height: 535px;
    overflow: hidden;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0 0 30px 0;
    }
`;
