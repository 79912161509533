import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const ButtonDeleteItemOrder = styled.button`
    &:hover {
        svg {
            path {
                stroke: ${({ theme }) => theme.colors.red()};
            }
        }
    }
`;

export const PriceItemOrder = styled(Typography)`
    margin-bottom: 1px;
`;

export const VatItemOrder = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('regular14')}
    }
`;

export const ButtonEditItemOrder = styled.button`
    &:hover {
        svg {
            path {
                stroke: ${({ theme }) => theme.colors.base()};
            }
        }
    }
`;

export const InformationItemOrder = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: contents;
    }
`;

export const ItemCompositionOrderWrapper = styled.div`
    padding: 22px 0;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    &:last-child {
        border: none;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: grid;
        grid-template-rows: 20px 20px;
        grid-template-columns: 1fr 60px;
        grid-row-gap: 17px;
        grid-column-gap: 20px;
    }
`;

export const ItemOrderButtonsWrapper = styled.div`
    display: grid;
    grid-template-row: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 14px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-row-start: 1;
        grid-template-columns: repeat(2, 16px);
        grid-column-gap: 16px;
        grid-column-start: 2;
        justify-content: flex-end;
    }
`;

export const ItemOrderPriceWrapper = styled.div`
    min-width: 50px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 1;
        grid-column-end: 3;
        ${({ theme }) => theme.mixins.flexStart};
        justify-content: space-between;
    }
`;

export const Title = styled(Typography)`
    width: auto;
`;
