import React, { FC, ReactNode } from 'react';
import { Arrow } from '@invoicebox/ui';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { ActionWrapper } from '../ActionWrapper';

const getTreeFromMenuItemId = (menuItems: TMenuItems, selectedMenuItemId: number) => {
    const findedTree = menuItems.find((menuItem) => menuItem.id === selectedMenuItemId)?.children;

    if (findedTree) {
        return findedTree;
    }

    // eslint-disable-next-line no-console
    console.error('not found children');
    return menuItems;
};

export type TMenuItemCommon = {
    id: number;
    label: string;
    icon?: ReactNode;
    onClick?: () => void;
    href?: string;
};

export type TMenuItemWithChildren = TMenuItemCommon & {
    children?: Array<TMenuItemCommon>;
};

export type TMenuItems = Array<TMenuItemWithChildren>;

export type TProps = {
    menuItems: TMenuItems;
    onMenuItemOpen: (id: TMenuItemWithChildren['id']) => void;
    afterClick: () => void;
    selectedMenuItemId?: number;
};

export const MoreMenu: FC<TProps> = ({ menuItems, selectedMenuItemId, onMenuItemOpen, afterClick }) => {
    const theme = useTheme();

    const currentMenuItems: Array<TMenuItemCommon> | Array<TMenuItemWithChildren> = selectedMenuItemId
        ? getTreeFromMenuItemId(menuItems, selectedMenuItemId)
        : menuItems;

    return (
        <S.Wrapper>
            <S.MenuItemsWrapper>
                {currentMenuItems.map((menuItem) => {
                    const isHaveChildren = 'children' in menuItem && menuItem.children;

                    return (
                        <S.MenuItemWrapper key={menuItem.id}>
                            <ActionWrapper
                                afterClick={isHaveChildren ? undefined : afterClick}
                                href={menuItem.href}
                                onClick={
                                    isHaveChildren
                                        ? () => {
                                              onMenuItemOpen(menuItem.id);
                                          }
                                        : menuItem.onClick
                                }
                            >
                                <S.MenuItemContentWrapper>
                                    {menuItem.icon && <S.MenuItemIconWrapper>{menuItem.icon}</S.MenuItemIconWrapper>}
                                    <S.MenuItemLabel variant="headline4">{menuItem.label}</S.MenuItemLabel>
                                    <S.ArrowWrapper>
                                        <Arrow
                                            palette={{ open: theme.colors.base() }}
                                            outterSize={24}
                                            isOpen
                                            defaultRotate={90}
                                        />
                                    </S.ArrowWrapper>
                                </S.MenuItemContentWrapper>
                            </ActionWrapper>
                        </S.MenuItemWrapper>
                    );
                })}
            </S.MenuItemsWrapper>
        </S.Wrapper>
    );
};
