import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { thunkHandler } from 'src/utils/helpers';
import { IMerchantSettlementBalance } from 'src/store/MerchantSettlementBalance/types';
import { getMerchantSettlementBalances } from 'src/store/MerchantSettlementBalance/http';
import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';

export const fetchMerchantSettlementBalances = createAsyncThunkWithErrorHandler(
    'merchant/getBalances',
    async (arg, thunkAPI) => {
        if (thunkAPI.getState().counterparty.ids.length > 0) {
            return thunkHandler(getMerchantSettlementBalances(), thunkAPI);
        }
        throw new Error('You need have organizations for getting settlement balances');
    },
);

interface IInitialState {
    loading: boolean;
}

export const merchantSettlementBalancesAdapter = createEntityAdapter<IMerchantSettlementBalance>();

const initialState = merchantSettlementBalancesAdapter.getInitialState<IInitialState>({
    loading: false,
});

const MerchantSettlementBalances = createSlice({
    name: 'merchantSettlementBalances',
    initialState,
    reducers: {
        clearMerchantSettlementBalance() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // fetchMerchantSettlementBalances
        builder.addCase(fetchMerchantSettlementBalances.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchMerchantSettlementBalances.fulfilled, (state, { payload }) => {
            state.loading = false;
            merchantSettlementBalancesAdapter.addMany(state, payload);
        });
        builder.addCase(fetchMerchantSettlementBalances.rejected, (state) => {
            state.loading = false;
        });
    },
});

export const { clearMerchantSettlementBalance } = MerchantSettlementBalances.actions;

export default MerchantSettlementBalances.reducer;
