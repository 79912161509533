import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type TProps = {
    icon: ReactNode;
    className?: string;
    withBadge?: boolean;
    isActive?: boolean;
};

const CircleIcon: FC<TProps> = ({ className, icon, withBadge = false, isActive = false }) => (
    <IconWrapper $withBadge={withBadge} $isActive={isActive} className={className}>
        {icon}
    </IconWrapper>
);

export default CircleIcon;

export const IconWrapper = styled.div<{
    $withBadge: boolean;
    $isActive: boolean;
}>`
    width: 36px;
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    background-color: ${({ theme, $isActive }) => ($isActive ? theme.colors.yellow() : theme.colors.grey200())};
    transition: ${({ theme }) => theme.decorations.transition.base};
    position: relative;

    svg {
        width: 20px;
    }

    ${({ $withBadge }) =>
        $withBadge &&
        css`
            &::after {
                content: '';
                position: absolute;
                top: -1px;
                right: -1px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: ${({ theme }) => theme.colors.red()};
                border: 2px solid ${({ theme }) => theme.colors.grey200()};
            }
        `}
`;
