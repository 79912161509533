import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const Empty = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: center;
`;

export const InnerContent = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.grey200()};
`;
