import React, { FC, useEffect } from 'react';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch } from 'src/store';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { currencyFormatter } from 'src/utils/formatters';
import { fetchMerchantsList } from 'src/store/Merchant';
import { getCurrentCounterpartyMerchants, getCurrentMerchantId } from 'src/store/Merchant/selectors';
import { fetchCounterpartiesList, fetchCounterpartyBankDetails } from 'src/store/Counterparty';
import { getCounterpartiesIsPending, getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { useLocation } from 'react-router-dom';
import { getSettlementBalances } from 'src/store/MerchantSettlementBalance/selectors';
import { fetchMerchantSettlementBalances } from 'src/store/MerchantSettlementBalance';
import ProductsAccordion from 'src/components/ProductsAccordion';
import { ReactComponent as InvoicingIcon } from 'src/assets/icons/invoicing.svg';
import { ReactComponent as BillsIcon } from 'src/assets/icons/bills.svg';
import { ICollapseBodyItem } from 'src/components/ProductsAccordion/components/CollapseItem';
import { fetchMerchantContractsList, fetchMerchantContractTemplates } from 'src/store/Contracts';
import { fetchClaims } from 'src/store/Claims';
import { useIntl } from 'react-intl';
import { Skeleton } from '@invoicebox/ui';
import * as S from './styles';
import DashboardHeader from '../../components/DashboardHeader';
import { Footer } from '../../stories/common/Footer';
import { BUSINESS_OFFICE_PATHS } from '../../pages/businessOffice/paths';
import { useInitCounterparty } from './hooks/useInitCounterparty';
import BottomMenuWidget from '../../components/BottomMenuWidget';
import { useMobile } from '../../hooks/useMedia';
import SidebarMenuWidget from '../../components/SidebarMenuWidget';

const MainLayout: FC = ({ children }) => {
    const intl = useIntl();
    const location = useLocation();
    const isMobile = useMobile();
    const dispatch = useAppDispatch();

    const counterpartiesIsPending = useSelector(getCounterpartiesIsPending);
    const selectedCounterpartyId = useSelector(getCurrentCounterpartyId);
    const selectedMerchantId = useSelector(getCurrentMerchantId) || undefined;
    const currentCounterpartyMerchants = useSelector(getCurrentCounterpartyMerchants);
    const balances = useSelector(getSettlementBalances);

    const isLoadingContextCounterparty = useInitCounterparty();

    const invoicingCollapseItems =
        currentCounterpartyMerchants?.map(({ name, id }) => ({
            id,
            title: name || intl.formatMessage({ id: 'Без имени', defaultMessage: 'Без имени' }),
            label: `${currencyFormatter(find(balances, { merchantId: id })?.settlementTotalAmount || 0)}`,
        })) || [];

    const ProductsAccordionWithSkeleton = counterpartiesIsPending ? S.ProductsAccordionSkeleton : ProductsAccordion;

    const renderInvoicingCollapseItem = ({ id, title, label, activeId }: ICollapseBodyItem) => (
        <S.MerchantLink
            $isActive={activeId === id}
            to={BUSINESS_OFFICE_PATHS.shop(selectedCounterpartyId).overview(id)}
        >
            <S.MerchantItemTitle variant="regular14">{title}</S.MerchantItemTitle>
            {label && <S.MerchantItemLabel variant="regular14">{label}</S.MerchantItemLabel>}
        </S.MerchantLink>
    );

    useEffect(() => {
        dispatch(fetchCounterpartiesList());
        dispatch(fetchMerchantsList({}));
        dispatch(fetchMerchantSettlementBalances());
        dispatch(fetchMerchantContractTemplates());
        dispatch(fetchCounterpartyBankDetails());
        dispatch(fetchClaims());
        dispatch(fetchMerchantContractsList());
    }, [dispatch]);

    return (
        <S.LayoutWrapper>
            <Helmet>
                <title>{intl.formatMessage({ defaultMessage: 'Инвойсбокс | Панель управления' })}</title>
            </Helmet>
            <DashboardHeader />
            <S.PageContainer>
                <aside>
                    {!isMobile && <SidebarMenuWidget />}
                    <S.NavbarTitle variant="headline">
                        {intl.formatMessage({ defaultMessage: 'Продукты' })}
                    </S.NavbarTitle>
                    <ProductsAccordionWithSkeleton>
                        <ProductsAccordion.CollapseItem
                            isActive={location.pathname.includes(RoutesBusinessOffice.shop)}
                            activeId={selectedMerchantId}
                            title={intl.formatMessage({
                                defaultMessage: 'Продажи',
                            })}
                            icon={<InvoicingIcon />}
                            items={invoicingCollapseItems}
                            renderItem={renderInvoicingCollapseItem}
                            errorTooltipContent={
                                !selectedCounterpartyId && (
                                    <div>
                                        {intl.formatMessage({
                                            defaultMessage: 'Для открытия меню выберите организацию',
                                        })}
                                    </div>
                                )
                            }
                        />
                        <ProductsAccordion.Item
                            to={BUSINESS_OFFICE_PATHS.payment(selectedCounterpartyId)}
                            title={intl.formatMessage({ defaultMessage: 'Входящие счета' })}
                            icon={<BillsIcon />}
                        />
                    </ProductsAccordionWithSkeleton>
                </aside>

                {isLoadingContextCounterparty ? <Skeleton /> : <S.PageInner>{children}</S.PageInner>}
            </S.PageContainer>
            <Footer />
            {isMobile && <BottomMenuWidget />}
        </S.LayoutWrapper>
    );
};

export default MainLayout;
