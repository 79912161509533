import React, { FC, ReactNode } from 'react';
import { Arrow, Dropdown, useOutsideClick } from '@invoicebox/ui';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { SearchButton } from '../SearchButton';

export type TProps = {
    title: string;
    subtitle: string;
    children: ReactNode;
    isOpen: boolean;
    onDropdownToggle: () => void;
    onDropdownClose: () => void;
    notificationItem: ReactNode;
    onSearchClick?: () => void;
};

export const BusinessOfficeHeaderActions: FC<TProps> = ({
    subtitle,
    title,
    children,
    onSearchClick,
    isOpen,
    onDropdownToggle,
    onDropdownClose,
    notificationItem,
}) => {
    const theme = useTheme();

    const wrapperRef = useOutsideClick(onDropdownClose);

    const arrowPalette = {
        close: theme.colors.base(),
        open: theme.colors.base(),
    };

    return (
        <S.Wrapper>
            <S.IconActionsWrapper>
                {notificationItem}
                {onSearchClick && <SearchButton onClick={onSearchClick} />}
            </S.IconActionsWrapper>
            <S.UserButtonWrapper ref={wrapperRef}>
                <S.UserButton type="button" onClick={onDropdownToggle}>
                    <S.UserData>
                        <S.Title variant="buttonM">{title}</S.Title>
                        <S.Subtitle variant="captionRegular">{subtitle}</S.Subtitle>
                    </S.UserData>
                    <Arrow palette={arrowPalette} isOpen={isOpen} />
                </S.UserButton>
                <Dropdown isOpen={isOpen} width="254px" positionLeft="auto" positionRight="0px">
                    {children}
                </Dropdown>
            </S.UserButtonWrapper>
        </S.Wrapper>
    );
};
