import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { DrawerMenuTitleHeader } from '../../stories/header/businessOffice/DrawerMenuTitleHeader';
import { MoreMenu, TMenuItems } from '../../stories/menu/MoreMenu';
import { DrawerMenuContainer } from '../../stories/common/DrawerMenuContainer';
import { useMenuItems } from '../../hooks/useMenuItems';

const getMenuItemLabelByMenuTreeKeys = (menuItems: TMenuItems, selectedMenuItemId: number) => {
    const findedMenuItem = menuItems.find((treeItem) => treeItem.id === selectedMenuItemId);

    if (findedMenuItem) {
        return findedMenuItem.label;
    }

    // eslint-disable-next-line no-console
    console.error('not found');
    return '';
};

type TProps = {
    isOpen: boolean;
    onClose: () => void;
    isBuyer: boolean;
};

const MoreMenuDrawer: FC<TProps> = ({ isOpen, onClose, isBuyer }) => {
    const intl = useIntl();

    const menuItems = useMenuItems(isBuyer);
    const [selectedMenuItemId, setSelectedMenuItemId] = useState<number>();

    const handleClose = () => {
        setSelectedMenuItemId(undefined);
        onClose();
    };

    const drawerTitle = selectedMenuItemId
        ? getMenuItemLabelByMenuTreeKeys(menuItems, selectedMenuItemId)
        : intl.formatMessage({ id: 'Ещё', defaultMessage: 'Ещё' });

    const handleMenuItemBack = () => setSelectedMenuItemId(undefined);

    const handleBack = selectedMenuItemId ? handleMenuItemBack : handleClose;

    return (
        <DrawerMenuContainer isOpen={isOpen} header={<DrawerMenuTitleHeader title={drawerTitle} onBack={handleBack} />}>
            <MoreMenu
                afterClick={handleClose}
                menuItems={menuItems}
                selectedMenuItemId={selectedMenuItemId}
                onMenuItemOpen={setSelectedMenuItemId}
            />
        </DrawerMenuContainer>
    );
};

export default MoreMenuDrawer;
