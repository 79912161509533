import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const ItemOrderWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexStart}
    justify-content: space-between;
    padding: 21px 0 17px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
`;

export const ItemName = styled(Typography)``;

export const ItemPrice = styled(Typography)`
    white-space: nowrap;
`;

export const ButtonsContainer = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 14px;
    margin-left: 17px;
    path {
        stroke: ${({ theme }) => theme.colors.grey()};
    }
`;

export const ItemDetail = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
`;

export const ButtonRemove = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}
    &:hover {
        path {
            stroke: ${({ theme }) => theme.colors.red()};
        }
    }
`;

export const ButtonEdit = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}

    &:hover {
        path {
            stroke: ${({ theme }) => theme.colors.base()};
        }
    }
`;
