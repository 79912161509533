import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from '../store/Counterparty/selectors';
import { getCurrentCounterpartyMerchants } from '../store/Merchant/selectors';

export const useIsBuyer = () => {
    const counterpartyId = useSelector(getCurrentCounterpartyId);
    const merchants = useSelector(getCurrentCounterpartyMerchants);

    return !counterpartyId || !merchants.length;
};
