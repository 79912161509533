import styled from 'styled-components';

export const IconButton = styled.button`
    padding: 11px;

    &:hover {
        opacity: 0.8;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0;
        width: 24px;
        height: 24px;

        path {
            stroke: ${({ theme }) => theme.colors.base(0.5)};
        }
    }
`;
