import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    background: ${({ theme }) => theme.colors.grey200()};
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
    padding: 8px 16px;
`;

export const ProfileButton = styled.button`
    width: 100%;
    padding: 8px 16px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    gap: 16px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.white()};
`;

export const ProfileIconAndTitle = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 16px;
`;

export const CounterpartiesWrapper = styled.div`
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.white()};
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0;
    overflow: scroll;
`;

export const CounterpartyButton = styled.button<{ $isActive: boolean }>`
    padding: 0 12px;
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 12px;
    position: relative;

    ${({ $isActive }) =>
        $isActive &&
        `&:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: block;
        height: 28px;
        width: 1.5px;
        background: #2baa5d;
    }`}
`;

export const CounterpartyAvatarWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    border-radius: 30px;
    background: ${({ theme }) => theme.colors.grey200()};
    width: 40px;
    height: 40px;
    flex-shrink: 0;
`;

export const CounterpartyData = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: left;
    overflow: hidden;
`;

export const CounterpartyTitle = styled(Typography)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const CounterpartySubTitle = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey700()};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const CounterpartyTags = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 12px;
    margin-right: 0;
    margin-left: auto;
`;

export const CounterpartyLabel = styled(Typography)`
    padding: 4px 6px;
    border-radius: 6px;
    background: rgba(0, 184, 217, 0.16);
    color: #006c9c;
    font-weight: 700;
`;

export const UserLabel = styled(Typography)`
    padding: 4px 6px;
    border-radius: 6px;
    background: rgba(142, 51, 255, 0.16);
    color: #5119b7;
    font-weight: 700;
`;

export const LogoutButton = styled.button`
    padding: 13px 24px;
    border-radius: 50px;
    border: 1px solid ${({ theme }) => theme.colors.grey900()};
`;
