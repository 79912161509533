import styled, { css } from 'styled-components';
import Typography from 'src/components/Typography';
import { ReactComponent as DecorationImage } from 'src/assets/icons/decoration.svg';

export const SlideTitle = styled.p`
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    white-space: nowrap;
    @media ${({ theme }) => theme.breakpoints.xxl} {
        white-space: normal;
    }
    @media ${({ theme }) => theme.breakpoints.xl} {
        white-space: nowrap;
    }
    @media ${({ theme }) => theme.breakpoints.ld} {
        white-space: normal;
    }
`;

export const ParagraphsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 22px;
    position: relative;
    transition:
        opacity 0.6s cubic-bezier(0.69, 0.01, 0.54, 0.98) 0.3s,
        max-height 0.6s cubic-bezier(0.69, 0.01, 0.54, 0.98),
        transform 0.45s cubic-bezier(0.69, 0.01, 0.54, 0.98) 0.3s,
        margin-top 0.6s cubic-bezier(0.69, 0.01, 0.54, 0.98);
`;

export const ParagraphSlide = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.5)};
`;

export const Decoration = styled(DecorationImage)`
    display: block;
    position: relative;
    transform-origin: left;
    transform: scale(1.4);
`;

interface ISliderBlock {
    $isActive: boolean;
    $numberSlide: number;
    $activeSlide: number;
}

const StyleActive = css`
    left: 0;
    top: 24px;
`;

const StyleQueueFirst = css`
    top: -20px;
    left: 100%;
    transform: translateX(-100%);
`;

const StyleQueueSecond = css`
    left: 100%;
    top: 50%;
    transform: translate(-100%, -50%);
`;

const StyleQueueThird = css`
    left: 100%;
    transform: translateX(-100%);
    top: 360px;
`;

const getStyle = (activeSlide: number, currentSlide: number) => {
    if (activeSlide === currentSlide) return StyleActive;
    if (currentSlide === activeSlide + 1 || currentSlide === activeSlide - 3) {
        return StyleQueueFirst;
    }
    if (currentSlide === activeSlide + 2 || currentSlide === activeSlide - 2) {
        return StyleQueueSecond;
    }
    if (currentSlide === activeSlide + 3 || currentSlide === activeSlide - 1) {
        return StyleQueueThird;
    }
};

const StyleSliderBlockDesktop = css<ISliderBlock>`
    padding: ${({ $isActive }) => ($isActive ? '40px 50px 55px 50px' : '25px 50px')};
    max-width: ${({ $isActive }) => ($isActive ? '494px' : '400px')};
    max-height: ${({ $isActive }) => ($isActive ? '534px' : '90px')};
`;

const StyleSliderTable = css<ISliderBlock>`
    padding: ${({ $isActive }) => ($isActive ? '50px 50px 55px 50px' : '22px 50px')};
    max-width: ${({ $isActive }) => ($isActive ? '434px' : '230px')};
    max-height: ${({ $isActive }) => ($isActive ? '400px' : '250px')};
`;

export const SliderBlock = styled.button<ISliderBlock>`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    border: 3px solid ${({ theme }) => theme.colors.white()};
    background: ${({ $isActive, theme }) => ($isActive ? theme.colors.grey200(0.5) : theme.colors.wrongGrey100())};

    height: auto;
    width: auto;

    ${StyleSliderBlockDesktop};

    box-shadow: ${({ $isActive }) => ($isActive ? 'none' : '-50px 76px 110px -12px rgba(40, 51, 58, 0.18)')};
    overflow: hidden;

    @media ${({ theme }) => theme.breakpoints.xxl} {
        ${StyleSliderTable}
    }

    @media ${({ theme }) => theme.breakpoints.xl} {
        ${StyleSliderBlockDesktop}
    }

    @media ${({ theme }) => theme.breakpoints.ld} {
        ${StyleSliderTable}
    }

    ${SlideTitle} {
        ${({ $isActive, theme }) =>
            $isActive ? theme.mixins.getTypography('title') : theme.mixins.getTypography('regular14')};

        text-align: ${({ $isActive }) => ($isActive ? 'left' : 'center')};
        transition:
            font-size 0.6s cubic-bezier(0.69, 0.01, 0.54, 0.98),
            line-height 0.6s cubic-bezier(0.69, 0.01, 0.54, 0.98);
    }

    ${Decoration} {
        left: ${({ $isActive }) => ($isActive ? '0' : '50%')};
        transform: ${({ $isActive }) => ($isActive ? '0' : 'translateX(-50%) scale(1.4)')};
        transition: 0.6s;
        margin-bottom: ${({ $isActive }) => ($isActive ? '14px' : '9px;')};
    }

    ${ParagraphsWrapper} {
        height: auto;
        overflow: hidden;
        max-height: ${({ $isActive }) => ($isActive ? '400px' : '0;')};
        transform: ${({ $isActive }) => ($isActive ? 'translateY(0)' : 'translateY(-100%)')};
        opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
        margin-top: ${({ $isActive }) => ($isActive ? '24px' : '0')};
    }

    ${({ $activeSlide, $numberSlide }) => getStyle($activeSlide, $numberSlide)};

    position: absolute;
    z-index: 4;
    transition: 0.6s;
    transition-timing-function: cubic-bezier(0.69, 0.01, 0.54, 0.98);
    transition-property: all;
`;
