import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 30px;
    height: 30px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    color: ${({ theme }) => theme.colors.grey500()};
    border: 1px solid ${({ theme }) => theme.colors.grey400()};
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.grey200()};
`;
