import React, { FC, ReactNode, useState } from 'react';
import PhoneEnterStep from '../PhoneEnterStep';
import CodeEnterStep from '../CodeEnterStep';
import { useAuth } from '../../../../../hooks/useAuth';

enum AUTH_STEP {
    phoneEnter = 'phoneEnter',
    codeEnter = 'codeEnter',
}

const AuthStep: FC = () => {
    const [authStep, setAuthStep] = useState(AUTH_STEP.phoneEnter);
    const goToSmsScreen = () => setAuthStep(AUTH_STEP.codeEnter);

    const { handleResendCode, sendCode, authByPhone, isCodeError, isExpired, timer, leftAttempts, isCanAgainSendCode } =
        useAuth({
            goToSmsStep: goToSmsScreen,
        });

    const smsStepMap: Record<AUTH_STEP, ReactNode> = {
        [AUTH_STEP.phoneEnter]: <PhoneEnterStep authByPhone={authByPhone} />,
        [AUTH_STEP.codeEnter]: (
            <CodeEnterStep
                onSubmit={sendCode}
                timer={timer}
                onGetNewCode={handleResendCode}
                isCodeError={isCodeError}
                leftAttempts={leftAttempts}
                isDisabled={isExpired || leftAttempts < 1}
                isCanAgainSendCode={isCanAgainSendCode}
            />
        ),
    };

    return <> {smsStepMap[authStep]} </>;
};

export default AuthStep;
