import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Drawer, Skeleton } from '@invoicebox/ui';
import * as S from './styles';
import DocsHeaderContentPageWidget from '../../../components/DocsHeaderContentPageWidget';
import { useAppDispatch } from '../../../store';
import { fetchTasks } from '../../../store/Task';
import { getTaskInitialized, getTaskIsLoading, getTaskList } from '../../../store/Task/selectors';
import TasksTable from './components/TasksTable';
import { Task } from '../../../store/Task/entity';
import TaskDetail from './components/TaskDetail';
import { DOCS_PATHS } from '../paths';
import { useMobile } from '../../../hooks/useMedia';
import useModal from '../../../hooks/useModal';
import {
    getDateArrOrNullFromQueryString,
    getQueryStringFromDateArrOrNull,
    useQueryFilter,
} from '../../../hooks/useQueryFilter';
import { convertDateToString } from '../../../utils/helpers';

const Statuses = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isMobile = useMobile();
    const { isOpen: isOpenTaskListDrawer, open: openTaskListDrawer, close: closeTaskListDrawer } = useModal(false);

    const taskList = useSelector(getTaskList);
    const taskInitialized = useSelector(getTaskInitialized);
    const taskIsLoading = useSelector(getTaskIsLoading);

    const [selectedTask, setSelectedTask] = useState<Task | undefined>(undefined);
    const [createdAtFilter, setCreatedAtFilter] = useQueryFilter<[Date, Date] | null>(
        'created',
        null,
        getDateArrOrNullFromQueryString,
        getQueryStringFromDateArrOrNull,
    );

    const handleGoToDashboard = () => {
        navigate(DOCS_PATHS.dashboard);
    };

    const handleSelectTask = useCallback(
        (task: Task) => {
            setSelectedTask(task);
            closeTaskListDrawer();
        },
        [closeTaskListDrawer],
    );

    useEffect(() => {
        if (taskList.length) {
            handleSelectTask(taskList[0]);
        }
    }, [handleSelectTask, taskList]);

    useEffect(() => {
        dispatch(
            fetchTasks({
                'createdAt[_ge]': createdAtFilter ? convertDateToString(createdAtFilter[0]) : undefined,
                'createdAt[lt]': createdAtFilter ? convertDateToString(createdAtFilter[1]) : undefined,
            }),
        );
    }, [createdAtFilter, dispatch]);

    const taskTable = taskInitialized ? (
        <S.TaskTableWrapper>
            <TasksTable
                setCreatedAtFilter={setCreatedAtFilter}
                createdAtFilter={createdAtFilter}
                taskList={taskList}
                selectedTaskId={selectedTask?.id}
                onSelectTask={handleSelectTask}
                isLoading={taskIsLoading}
            />
        </S.TaskTableWrapper>
    ) : (
        <Skeleton width="370px" height="300px" />
    );

    return (
        <S.Wrapper>
            <DocsHeaderContentPageWidget
                title={intl.formatMessage({
                    id: 'Проверка статуса отправления документов',
                    defaultMessage: 'Проверка статуса отправления документов',
                })}
                isHaveStatusButton={false}
                onBack={handleGoToDashboard}
            />
            <S.Content>
                {isMobile ? (
                    <Drawer onClose={closeTaskListDrawer} isOpen={isOpenTaskListDrawer}>
                        {taskTable}
                    </Drawer>
                ) : (
                    taskTable
                )}
                {selectedTask && (
                    <TaskDetail
                        task={selectedTask}
                        onOpenTaskListDrawer={openTaskListDrawer}
                        isOpenTaskListDrawer={isOpenTaskListDrawer}
                    />
                )}
            </S.Content>
        </S.Wrapper>
    );
};

export default Statuses;
