import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';
import { thunkHandler } from 'src/utils/helpers';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { fetchApplicationListRequest } from 'src/store/Application/http';
import { TApplication } from 'src/store/Application/types';
import { Nullable } from 'src/utils/utilityTypes';

export const fetchApplicationList = createAsyncThunkWithErrorHandler(
    'application/fetchApplicationList',
    async (data, thunkAPI) => thunkHandler(fetchApplicationListRequest(), thunkAPI),
);

export const applicationAdapter = createEntityAdapter<TApplication>();

const initialState = applicationAdapter.getInitialState<{ isLoading: boolean; selected: Nullable<TApplication> }>({
    isLoading: false,
    selected: null,
});

const Application = createSlice({
    name: 'application',
    initialState,
    reducers: {
        clearApplicationList() {
            return initialState;
        },
        setSelectedApplication(state, { payload }) {
            state.selected = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchApplicationList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchApplicationList.fulfilled, (state, action) => {
            applicationAdapter.upsertMany(state, action.payload);
        });
        builder.addCase(fetchApplicationList.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { clearApplicationList, setSelectedApplication } = Application.actions;
export default Application.reducer;
