import styled from 'styled-components';

export const Wrapper = styled.div<{ $isOpen: boolean }>`
    top: 0;
    position: fixed;
    right: ${({ $isOpen }) => ($isOpen ? '0' : '-100%')};
    z-index: 10;
    width: 100%;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.white()};
    transition: ${({ theme }) => theme.decorations.transition.base};
    display: flex;
    flex-direction: column;
`;
