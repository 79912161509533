import React from 'react';

export const UserIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.28565 15.1004C6.28485 14.8914 6.3316 14.6849 6.42235 14.4966C6.70714 13.9271 7.51024 13.6252 8.17665 13.4885C8.65726 13.3859 9.1445 13.3174 9.63476 13.2835C10.5424 13.2037 11.4553 13.2037 12.363 13.2835C12.8532 13.3178 13.3405 13.3863 13.8212 13.4885C14.4875 13.6252 15.2906 13.8986 15.5754 14.4966C15.758 14.8804 15.758 15.326 15.5754 15.7098C15.2906 16.3079 14.4875 16.5813 13.8212 16.7123C13.3411 16.8191 12.8537 16.8895 12.363 16.923C11.6243 16.9857 10.882 16.9971 10.1417 16.9572C9.97081 16.9572 9.80563 16.9572 9.63476 16.923C9.14595 16.89 8.66039 16.8195 8.18234 16.7123C7.51024 16.5813 6.71284 16.3079 6.42235 15.7098C6.33206 15.5194 6.28536 15.3112 6.28565 15.1004Z"
            stroke="#353F47"
            strokeWidth="1.40009"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.0025 10.9661C12.6445 10.9661 13.9757 9.635 13.9757 7.99296C13.9757 6.35092 12.6445 5.01978 11.0025 5.01978C9.36044 5.01978 8.0293 6.35092 8.0293 7.99296C8.0293 9.635 9.36044 10.9661 11.0025 10.9661Z"
            stroke="#353F47"
            strokeWidth="1.40009"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
