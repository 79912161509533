import React, { FC } from 'react';
import * as S from './styles';
import { SearchButton } from '../SearchButton';
import { NameCircle } from '../../common/NameCircle';
import NotificationMenuItem from '../../../../components/NotificationMenuItem';

export type TProps = {
    name: string;
    onUserClick: () => void;
    onSearchClick?: () => void;
};

export const BusinessOfficeMobileHeader: FC<TProps> = ({ name, onSearchClick, onUserClick }) => (
    <S.Wrapper>
        <S.UserButtonWrapper type="button" onClick={onUserClick}>
            <NameCircle name={name} />
        </S.UserButtonWrapper>
        <S.ActionsWrapper>
            {onSearchClick && (
                <>
                    <SearchButton onClick={onSearchClick} /> <S.ActionDivider />
                </>
            )}
            <NotificationMenuItem />
        </S.ActionsWrapper>
    </S.Wrapper>
);
