import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { OrderContainer } from './entity';
import { getOrderContainerListRequest, TOrderContainerListFilter } from './http';
import { getCurrencyById } from '../Currency/selectors';
import { RootState } from '../index';

export const orderContainerAdapter = createEntityAdapter<OrderContainer>();

const initialState = orderContainerAdapter.getInitialState({
    isInitialized: false,
    isLoading: false,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

export const fetchOrderContainerList = createAsyncThunk(
    'orderContainer/fetchList',
    async (filter: TOrderContainerListFilter, { getState }) => {
        const { data } = await getOrderContainerListRequest(filter);

        const orderContainerList = data.data.map((orderContainerItem) => ({
            ...orderContainerItem,
            currency: getCurrencyById(orderContainerItem.originalCurrencyId)(getState() as RootState),
        }));

        return { ...data, data: orderContainerList };
    },
);

const orderContainerSlice = createSlice({
    name: 'orderContainer',
    initialState,
    reducers: {
        clearOrderContainerList() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrderContainerList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchOrderContainerList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isInitialized = true;
            state.metaData = payload.metaData;
            orderContainerAdapter.setAll(
                state as EntityState<OrderContainer>,
                payload.data.map((orderContainerFromResponse) =>
                    OrderContainer.fromResponse(orderContainerFromResponse),
                ),
            );
        });
        builder.addCase(fetchOrderContainerList.rejected, (state) => {
            state.isLoading = false;
            state.isInitialized = true;
        });
    },
});

export const { clearOrderContainerList } = orderContainerSlice.actions;

export default orderContainerSlice.reducer;
