import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Skeleton from 'src/components/Skeleton';

export const RefundItemWrapper = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    height: 56px;
    padding: 11px 0;
    display: grid;
    grid-template-rows: repeat(2, 20px);
    grid-template-columns: 1fr 25% 22%;
    grid-column-gap: 20px;
    grid-row-gap: 2px;
    overflow: hidden;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: 1fr 100px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        height: 70px;
        padding: 18px 0;
    }
`;

export const RefundItemName = styled(Typography)`
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    overflow: hidden;
    line-height: 20px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-row-end: 2;
    }
`;

export const Amount = styled(Typography)`
    line-height: 20px;
    text-align: right;
    @media ${({ theme }) => theme.breakpoints.ld} {
        ${({ theme }) => theme.mixins.getTypography('regular10')}
    }
`;

export const TotalAmount = styled(Typography)`
    line-height: 20px;
    text-align: right;
`;

export const RefundType = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: right;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    white-space: nowrap;
`;

export const RefundItemSkeleton = styled(Skeleton)`
    width: 100px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 80px;
    }
`;

export const Vat = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: right;
    grid-column-start: 3;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-start: auto;
    }
`;

export const AmountAndType = styled.div`
    display: contents;
    @media ${({ theme }) => theme.breakpoints.ld} {
        ${({ theme }) => theme.mixins.flexStart}
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-gap: 2px;
    }
`;
