import styled from 'styled-components';

export const ArrowNavigationButton = styled.button<{
    $isNextButton: boolean;
    disabled?: boolean;
}>`
    width: 20px;
    height: 20px;
    margin: 0 12px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.min}px;
    ${({ $isNextButton }) => $isNextButton && 'transform: rotateY(180deg)'};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'visible')};
    path {
        stroke: ${({ theme, disabled }) => (disabled ? theme.colors.grey() : theme.colors.base())};
    }

    &:hover {
        transition: 0.3s;
        background: ${({ theme, disabled }) => (disabled ? 'none' : theme.colors.grey200())};
    }
`;

export const SplitterNumbers = styled.span`
    margin: 0 6px;
`;
