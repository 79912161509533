import styled from 'styled-components';

export const NotificationButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    width: 46px;
    height: 46px;

    &:hover {
        path {
            opacity: 0.8;
        }
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 24px;
        height: 24px;

        path {
            stroke: ${({ theme }) => theme.colors.base(0.5)};
        }
    }
`;

export const NotificationWrapper = styled.div`
    position: relative;
`;
