import styled, { css } from 'styled-components';
import Typography from 'src/components/Typography';
import { NavLink } from 'react-router-dom';
import { IconWrapper } from 'src/components/CircleIcon';
import Modal from 'src/components/Modal';

export const AccordionWrapper = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.grey200()};
`;

export const ClickableItem = styled.button<{
    $isActive?: boolean;
}>`
    ${({ theme }) => theme.mixins.dropdownItem};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    background-color: ${({ theme, $isActive }) => ($isActive ? theme.colors.grey200(0.5) : theme.colors.white())};

    &:hover {
        background-color: ${({ theme }) => theme.colors.grey200(0.5)};
    }
`;

export const ClickableItemLink = styled(NavLink)`
    ${({ theme }) => theme.mixins.dropdownItem};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.white()};

    &:hover {
        background-color: ${({ theme }) => theme.colors.grey200(0.5)};
    }
    ${css`
        &.active {
            background-color: ${({ theme }) => theme.colors.grey200(0.5)};
            ${IconWrapper} {
                background-color: ${({ theme }) => theme.colors.yellow()};
            }
        }
    `}
`;

export const Title = styled(Typography)`
    margin-left: 20px;
    margin-right: auto;
    padding-right: 20px;
    ${({ theme }) => theme.mixins.textOverflowDots};
`;

export const CollapseTitle = styled(Typography)`
    margin-left: 0;
    margin-right: auto;
    padding-right: 20px;
    ${({ theme }) => theme.mixins.textOverflowDots};
`;

export const Label = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    white-space: nowrap;
`;

export const CollapseItemWrapper = styled.div<{
    $isOpen?: boolean;
}>`
    border-bottom: 1px solid ${({ theme, $isOpen }) => ($isOpen ? theme.colors.grey200() : 'transparent')};
`;

export const CollapseBody = styled.div<{
    $itemHeight: number;
}>`
    overflow: hidden;
    transition: ${({ theme }) => theme.decorations.transition.base};
    ${({ $itemHeight }) => css`
        height: ${$itemHeight}px;
    `}
`;

export const CollapseBodyItemWrapper = styled.div<{
    $itemHeight: number;
}>`
    height: ${({ $itemHeight }) => $itemHeight}px;
    transition: ${({ theme }) => theme.decorations.transition.base};

    &:hover {
        background-color: ${({ theme }) => theme.colors.grey200(0.5)};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    }
`;

export const CollapseBodyItem = styled.button`
    ${({ theme }) => theme.mixins.dropdownItem};
    padding-left: 56px;
`;

export const ArrowWrapper = styled.div<{
    $isOpen?: boolean;
}>`
    display: flex;
    pointer-events: none;
    transform: rotate(0);
    transition: ${({ theme }) => theme.decorations.transition.base};
    ${({ $isOpen }) =>
        $isOpen &&
        css`
            transform: rotate(180deg);
        `};
`;

export const ButtonAddMerchant = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    width: 100%;
    height: 100%;
    padding-left: 50px;
    grid-column-gap: 10px;
    svg {
        path {
            stroke: ${({ theme }) => theme.colors.base()};
        }
    }
`;

export const ModalMerchantCreate = styled(Modal)``;
