import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const OrderItemsContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.grey200()};
`;

export const OrderItemsHeader = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    justify-content: space-between;
    margin-bottom: 19px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 27px;
    }
`;

export const ButtonAddNewItem = styled.button`
    ${({ theme }) => theme.mixins.getTypography('regular12')};
    color: ${({ theme }) => theme.colors.grey()};
    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }
`;

export const Title = styled(Typography)``;
