import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { toast } from '@invoicebox/ui';
import * as S from './styles';
import Button from '../../../../../stories/common/Button';
import FormProvider from '../../../../../components/FormProvider';
import { defaultValues, REQUISITE_FORM_FIELD, TInnerForm } from './types';
import { requisiteSchema } from './validation';
import { RHFTextInput } from '../../../../../stories/formFields/RHFTextInput';
import { RHFRusVatNumberAutocomplete } from '../../../../../stories/formFields/widgets/RHFRusVatNumberAutocomplete';
import { useMobile } from '../../../../../hooks/useMedia';
import Subtitle from '../Subtitle';
import { setCounterpartyAccess } from '../../../../../store/TaskFlow/http';
import { DOCS_PATHS } from '../../../paths';

const RequisiteStep: FC = () => {
    const intl = useIntl();
    const isMobile = useMobile();
    const navigate = useNavigate();

    const methods = useForm<TInnerForm>({
        defaultValues,
        resolver: yupResolver(requisiteSchema),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting },
    } = methods;

    const submit = useCallback(
        (formValue: TInnerForm) =>
            setCounterpartyAccess({
                ...formValue,
                invoiceNumber: formValue.invoiceNumber.trim(),
                paymentOrderNumber: formValue.paymentOrderNumber.trim(),
            })
                .then((data) => {
                    if (data) {
                        navigate(DOCS_PATHS.dashboard);
                    }
                })
                .catch((error) => {
                    // когда счёт уже привязан к пользователю
                    if (error?.response?.data?.error?.code === 'already_exist') {
                        navigate(DOCS_PATHS.dashboard);
                    } else {
                        toast.error(
                            intl.formatMessage({
                                id: 'Произошла ошибка, попробуйте снова',
                                defaultMessage: 'Произошла ошибка, попробуйте снова',
                            }),
                        );
                    }
                }),
        [intl, navigate],
    );

    return (
        <>
            <Subtitle
                steps="2/2"
                label={intl.formatMessage({
                    defaultMessage: 'Для поиска счета, укажите его номер и реквизиты платежа',
                })}
            />
            <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
                <S.Wrapper>
                    <S.Inputs>
                        <RHFRusVatNumberAutocomplete name={REQUISITE_FORM_FIELD.vatNumber} />
                        <RHFTextInput
                            name={REQUISITE_FORM_FIELD.invoiceNumber}
                            label={intl.formatMessage({ defaultMessage: 'Номер оплаченного счёта' })}
                        />
                        <RHFTextInput
                            name={REQUISITE_FORM_FIELD.paymentOrderNumber}
                            label={intl.formatMessage({ defaultMessage: 'Номер платёжного поручения' })}
                        />
                    </S.Inputs>
                    <Button
                        fullWidth={isMobile}
                        htmlType="submit"
                        disabled={!isValid}
                        size="large"
                        isLoading={isSubmitting}
                    >
                        {intl.formatMessage({ defaultMessage: 'Найти счёт' })}
                    </Button>
                </S.Wrapper>
            </FormProvider>
        </>
    );
};

export default RequisiteStep;
