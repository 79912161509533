import styled, { css } from 'styled-components';
import Typography from 'src/components/Typography';
import { ReactComponent as OrderIcon } from 'src/assets/icons/order.svg';

export const InnerText = styled(Typography)`
    ${({ theme }) => theme.mixins.textOverflowDots}
    padding-right: 10px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.regular12.fontSize}px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-right: 0;
    }
`;

const stylesStatusAmount = css`
    text-align: right;

    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.regular12.fontSize}px;
    }
`;

export const Status = styled(Typography)`
    ${stylesStatusAmount}
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: none;
    }
`;

export const Amount = styled(Typography)`
    ${stylesStatusAmount}
`;

export const TableRow = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr 4fr 5fr 3fr 3fr;
    padding: 30px;

    :not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 1fr 4fr 4fr;
        padding: 30px 20px;
    }
`;

export const PaymentData = styled.div`
    display: contents;
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: none;
    }
`;

export const OrderIconWrapper = styled(OrderIcon)`
    display: none;
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: block;
    }
`;
