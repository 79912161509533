import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Arrow, Typography } from '@invoicebox/ui';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { Task } from '../../../../../store/Task/entity';
import { INVOICE_IDS_FIELD_NAME } from '../../../../../store/TaskFlow/types';
import { FIELD_LABEL } from '../../../../../stories/docs/FieldsBySchema/constants';
import { getTaskPlaceByTaskId } from '../../../../../store/TaskPlace/selectors';
import { Stepper, TStep } from '../../../../../stories/common/Stepper';
import { TASK_PLACE_CODE_LABEL } from '../../../../../store/TaskPlace/constants';
import { DOCS_PATHS } from '../../../paths';
import { useMobile } from '../../../../../hooks/useMedia';
import { COUNTERPARTY_ID_QUERY_NAME, ORDER_CONTAINER_IDS_QUERY_NAME } from '../../../constants';
import { DATA_SPLITTER } from '../../../../../hooks/useQueryFilter';

type TProps = {
    task: Task;
    onOpenTaskListDrawer: () => void;
    isOpenTaskListDrawer: boolean;
};

const TaskDetail: FC<TProps> = ({ task, onOpenTaskListDrawer, isOpenTaskListDrawer }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const isMobile = useMobile();
    const theme = useTheme();

    const taskPlaceList = useSelector(getTaskPlaceByTaskId(task.id));
    const steps = task.places
        .map((placeItem) => {
            const taskPlace = taskPlaceList.find((taskPlaceItem) => taskPlaceItem.placeCode === placeItem);
            if (placeItem === 'error' && !taskPlace) {
                return undefined;
            }

            return {
                title: TASK_PLACE_CODE_LABEL[placeItem] || placeItem,
                subtitle: taskPlace ? (
                    <Typography variant="captionRegular"> {taskPlace.createdDateShowFormatted()} </Typography>
                ) : (
                    ''
                ),
                isActive: !!taskPlace,
            };
        })
        .filter((i) => i) as Array<TStep>;

    const serializedInvoiceIdsArray = task.data.invoiceIds?.join(DATA_SPLITTER);
    const queryParams = new URLSearchParams({
        [ORDER_CONTAINER_IDS_QUERY_NAME]: serializedInvoiceIdsArray,
        [COUNTERPARTY_ID_QUERY_NAME]: task.counterpartyId || '',
    });

    const handleSendOtherWay = () => {
        navigate({
            pathname: DOCS_PATHS.dashboard,
            search: queryParams.toString(),
        });
    };

    const taskProperties = Object.entries(task.data)
        .map((property) => ({ label: FIELD_LABEL[property[0]] || property[0], value: property[1] }))
        .filter((property) => property.label !== INVOICE_IDS_FIELD_NAME);

    const title = `${intl.formatMessage({ id: 'Пакет документов', defaultMessage: '' })} ${task.id}`;

    return (
        <S.Wrapper>
            {isMobile ? (
                <S.MobileButtonOpenTaskListWrapper>
                    <S.MobileButtonOpenTaskList onClick={onOpenTaskListDrawer}>
                        <Typography variant="buttonM">{title}</Typography>
                        <Arrow
                            palette={{ open: theme.colors.base(), close: theme.colors.base() }}
                            outterSize={18}
                            isOpen={isOpenTaskListDrawer}
                        />
                    </S.MobileButtonOpenTaskList>
                </S.MobileButtonOpenTaskListWrapper>
            ) : (
                <Typography variant="headline4"> {title} </Typography>
            )}
            <S.TaskContent>
                <S.TaskDataWrapper>
                    <S.PropertyWrapper>
                        <S.PropertyLabel variant="captionRegular">
                            {intl.formatMessage({ id: 'Номер отправления', defaultMessage: 'Номер отправления' })}
                        </S.PropertyLabel>
                        <Typography variant="bodyMRegular">{task.id}</Typography>
                    </S.PropertyWrapper>
                    {taskProperties.map((property) => (
                        <S.PropertyWrapper key={property.label}>
                            <S.PropertyLabel variant="captionRegular">{property.label}</S.PropertyLabel>
                            <Typography variant="bodyMRegular">{property.value}</Typography>
                        </S.PropertyWrapper>
                    ))}
                </S.TaskDataWrapper>
                {!!taskPlaceList.length && <Stepper steps={steps} />}
            </S.TaskContent>
            <S.OtherWaySendButtonWrapper>
                <S.ButtonOtherWaySend fullWidth={isMobile} type="secondary" onClick={handleSendOtherWay}>
                    {intl.formatMessage({
                        id: 'Отправить другим способом',
                        defaultMessage: 'Отправить другим способом',
                    })}
                </S.ButtonOtherWaySend>
            </S.OtherWaySendButtonWrapper>
        </S.Wrapper>
    );
};

export default TaskDetail;
