export enum REQUISITE_FORM_FIELD {
    vatNumber = 'vatNumber',
    invoiceNumber = 'invoiceNumber',
    paymentOrderNumber = 'paymentOrderNumber',
}

export type TInnerForm = {
    [REQUISITE_FORM_FIELD.vatNumber]: string;
    [REQUISITE_FORM_FIELD.invoiceNumber]: string;
    [REQUISITE_FORM_FIELD.paymentOrderNumber]: string;
};

export const defaultValues = {
    [REQUISITE_FORM_FIELD.vatNumber]: '',
    [REQUISITE_FORM_FIELD.invoiceNumber]: '',
    [REQUISITE_FORM_FIELD.paymentOrderNumber]: '',
};
