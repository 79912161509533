import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const MenuItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const MenuItem = styled(NavLink)`
    padding: 20px 24px;
    width: 100%;
    text-align: left;
    position: relative;
    text-decoration: none;
    border-bottom: 1px dashed ${({ theme }) => theme.colors.grey500()};

    &:after {
        content: '';
        left: 0;
        top: 0;
        display: block;
        height: 0;
        width: 2px;
        background-color: ${({ theme }) => theme.colors.base()};
        position: absolute;
        border-radius: 0 2px 2px 0;
        transition: ${({ theme }) => theme.decorations.transition.base};
    }

    &.active {
        &:after {
            height: 100%;
        }
    }

    &:last-child {
        border: none;
    }
`;
