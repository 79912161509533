import React, { FC } from 'react';
import { Drawer, TDrawerProps } from '@invoicebox/ui';
import { useMobile } from '../../../hooks/useMedia';
import { Modal } from '../Modal';

export type TProps = {
    isOpen: boolean;
    onClose: () => void;
    drawerProps?: Omit<TDrawerProps, 'isOpen' | 'onClose' | 'children'>;
};

export const AdaptiveModalDrawer: FC<TProps> = ({ children, isOpen, onClose, drawerProps }) => {
    const isMobile = useMobile();

    if (isMobile) {
        return (
            <Drawer onClose={onClose} isOpen={isOpen} {...drawerProps}>
                {children}
            </Drawer>
        );
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            {children}
        </Modal>
    );
};
