import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { BusinessOfficeHeaderActions } from 'src/stories/header/businessOffice/BusinessOfficeHeaderActions';
import { Header } from '../../stories/header/common/Header';
import { logout } from '../../store/Auth';
import { useAppDispatch } from '../../store';
import { getIdentifier, getUserFirstName, getUserLastName, getUserName } from '../../store/Auth/selectors';
import { convertPhoneToShow } from '../../utils/formatters';
import {
    getAllCounterparties,
    getCurrentCounterparty,
    getCurrentCounterpartyId,
} from '../../store/Counterparty/selectors';
import { HeaderUserDropdown } from '../../stories/header/businessOffice/HeaderUserDropdown';
import NotificationMenuItem from '../NotificationMenuItem';
import { BUSINESS_OFFICE_PATHS } from '../../pages/businessOffice/paths';
import { useMobile } from '../../hooks/useMedia';
import BusinessOfficeMobileHeaderWidget from '../BusinessOfficeMobileHeaderWidget';
import useModal from '../../hooks/useModal';

const DashboardHeader = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const intl = useIntl();
    const isMobile = useMobile();

    const firstName = useSelector(getUserFirstName) || '';
    const lastName = useSelector(getUserLastName) || '';
    const userName = useSelector(getUserName) || '';
    const userIdentifier = useSelector(getIdentifier);
    const currentCounterparty = useSelector(getCurrentCounterparty);
    const counterparties = useSelector(getAllCounterparties);
    const selectedCounterpartyId = useSelector(getCurrentCounterpartyId);

    const {
        isOpen: isOpenUserContextMenu,
        open: openUserContextMenu,
        close: closeUserContextMenu,
        toggle: toggleUserContextMenu,
    } = useModal(false);

    const identifier =
        userIdentifier?.identifier && userIdentifier?.type === 'phone'
            ? convertPhoneToShow(userIdentifier.identifier)
            : userIdentifier?.identifier || '';

    const logoutHandler = async () => {
        closeUserContextMenu();
        dispatch(logout());
    };

    const goToProfile = () => {
        closeUserContextMenu();
        navigate(BUSINESS_OFFICE_PATHS.profile(currentCounterparty?.id));
    };

    const selectOrganization = (counterpartyId?: string) => {
        closeUserContextMenu();
        navigate(BUSINESS_OFFICE_PATHS.dashboard(counterpartyId));
    };

    const menuLinks = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: 'Главная', defaultMessage: 'Главная' }),
                href: BUSINESS_OFFICE_PATHS.dashboard(selectedCounterpartyId),
            },
        ],
        [intl, selectedCounterpartyId],
    );

    const handleLogout = () => {
        closeUserContextMenu();
        dispatch(logout());
    };

    if (isMobile) {
        return (
            <BusinessOfficeMobileHeaderWidget
                onLogout={handleLogout}
                onProfileGoTo={goToProfile}
                userName={userName}
                identifier={identifier}
                onOrganizationSelect={selectOrganization}
                counterparties={counterparties}
                selectedCounterparty={currentCounterparty}
                isOpenUserDrawer={isOpenUserContextMenu}
                closeUserDrawer={closeUserContextMenu}
                openUserDrawer={openUserContextMenu}
            />
        );
    }

    return (
        <Header
            menuLinks={menuLinks}
            userActions={
                <BusinessOfficeHeaderActions
                    isOpen={isOpenUserContextMenu}
                    onDropdownToggle={toggleUserContextMenu}
                    onDropdownClose={closeUserContextMenu}
                    title={`${lastName} ${firstName ? `${firstName[0]}.` : ''}`}
                    subtitle={currentCounterparty ? currentCounterparty.name : identifier}
                    notificationItem={<NotificationMenuItem />}
                >
                    <HeaderUserDropdown
                        userFullName={userName}
                        identifier={identifier}
                        onLogout={logoutHandler}
                        onProfileGoTo={goToProfile}
                        onOrganizationSelect={selectOrganization}
                        counterparties={counterparties}
                        selectedCounterparty={currentCounterparty}
                    />
                </BusinessOfficeHeaderActions>
            }
        />
    );
};

export default DashboardHeader;
