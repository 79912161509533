import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { TaskFlow } from './entity';
import { getTaskFlowListRequest } from './http';

export const taskFlowAdapter = createEntityAdapter<TaskFlow>({
    selectId: (taskFlow: TaskFlow) => taskFlow.taskflowCode,
});

const initialState = taskFlowAdapter.getInitialState({
    isInitialized: false,
    isLoading: false,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

export const fetchTaskFlowList = createAsyncThunk('taskFlow/fetchList', async () => {
    const { data } = await getTaskFlowListRequest();

    return data;
});

const taskFlowSlice = createSlice({
    name: 'taskFlow',
    initialState,
    reducers: {
        clearTaskFlow() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTaskFlowList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchTaskFlowList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isInitialized = true;
            state.metaData = payload.metaData;
            taskFlowAdapter.setAll(
                state as EntityState<TaskFlow>,
                payload.data.map((taskFlowFromResponse) => TaskFlow.fromResponse(taskFlowFromResponse)),
            );
        });
        builder.addCase(fetchTaskFlowList.rejected, (state) => {
            state.isLoading = false;
            state.isInitialized = true;
        });
    },
});

export const { clearTaskFlow } = taskFlowSlice.actions;

export default taskFlowSlice.reducer;
