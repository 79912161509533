import React, { FC } from 'react';
import * as S from './styles';

export type TProps = {
    href?: string;
    onClick?: () => void;
    afterClick?: () => void;
};

export const ActionWrapper: FC<TProps> = ({ children, afterClick, href, onClick }) => {
    if (href) {
        return (
            <S.MenuItemLink onClick={afterClick} to={href}>
                {children}
            </S.MenuItemLink>
        );
    }

    const handleClick = () => {
        if (onClick) {
            onClick();
            if (afterClick) {
                afterClick();
            }
        }
    };

    return (
        <S.MenuItemButton type="button" onClick={handleClick}>
            {children}
        </S.MenuItemButton>
    );
};
