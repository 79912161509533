const VALID_DOMAINS = ['invoicebox.ru', 'invbox.ru'];

export const getOrderContainerIdFromUrl = (url: string | undefined): string | null => {
    if (!url) return null;

    if (!VALID_DOMAINS.find((domain) => url.includes(domain))) {
        return null;
    }

    try {
        const match = url.match(/order\/([^/]+)/);
        return match ? match[1] : null;
    } catch (error) {
        return null;
    }
};
