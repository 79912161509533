import styled from 'styled-components';

export const TotalValuesBlock = styled.div`
    margin-bottom: 47px;
    padding-bottom: 50px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    @media ${({ theme }) => theme.breakpoints.md} {
        position: static;
    }
`;

export const TotalValues = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 4px;
    margin-top: 20px;
`;

export const TotalWrapper = styled.div`
    padding: 20px 0 23px 50px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding-left: 20px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 25px 0 0 0;
        position: relative;

        &:before {
            content: '';
            display: block;
            width: 120%;
            margin-left: -40px;
            height: 1px;
            background: ${({ theme }) => theme.colors.grey()};
            position: absolute;
            top: 0;
        }
    }
`;
