import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Scrollbar, Typography } from '@invoicebox/ui';
import * as S from './styles';
import { ICounterparty } from '../../../../store/Counterparty/types';
import { ReactComponent as LogoutIcon } from './assets/logout.svg';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { UserIcon } from '../UserIcon';
import { OrganizationIcon } from '../OrganizationIcon';

export type TProps = {
    userFullName: string;
    identifier: string;
    onLogout: () => void;
    onProfileGoTo: () => void;
    onOrganizationSelect: (counterpartyId?: string) => void;
    selectedCounterparty?: ICounterparty;
    counterparties?: Array<ICounterparty>;
};

export const HeaderUserDropdown: FC<TProps> = ({
    userFullName,
    identifier,
    counterparties,
    selectedCounterparty,
    onLogout,
    onProfileGoTo,
    onOrganizationSelect,
}) => {
    const intl = useIntl();

    return (
        <div>
            <S.UserWrapper>
                <S.FullName variant="bodyMRegular">{userFullName}</S.FullName>
                <S.Subtitle variant="bodyMRegular">
                    {selectedCounterparty ? selectedCounterparty.name : identifier}
                </S.Subtitle>
            </S.UserWrapper>
            <S.Divider />
            {!!counterparties?.length && (
                <>
                    <Scrollbar maxHeight={400}>
                        <S.OrganizationList>
                            <S.OrganizationWrapper
                                type="button"
                                $isActive={!selectedCounterparty}
                                onClick={() => onOrganizationSelect()}
                            >
                                <S.OrganizationAvatarWrapper>
                                    <UserIcon />
                                </S.OrganizationAvatarWrapper>
                                <S.OrganizationData>
                                    <S.OrganizationName variant="captionRegular">{userFullName}</S.OrganizationName>
                                    <S.OrganizationVatNumber variant="captionRegular">
                                        {identifier}
                                    </S.OrganizationVatNumber>
                                </S.OrganizationData>
                                <S.OrganizationTags>{!selectedCounterparty && <CheckIcon />}</S.OrganizationTags>
                            </S.OrganizationWrapper>
                            {counterparties.map((counterparty) => (
                                <S.OrganizationWrapper
                                    key={counterparty.id}
                                    type="button"
                                    $isActive={counterparty.id === selectedCounterparty?.id}
                                    onClick={() => onOrganizationSelect(counterparty.id)}
                                >
                                    <S.OrganizationAvatarWrapper>
                                        <OrganizationIcon />
                                    </S.OrganizationAvatarWrapper>
                                    <S.OrganizationData>
                                        <S.OrganizationName variant="captionRegular">
                                            {counterparty.name}
                                        </S.OrganizationName>
                                        <S.OrganizationVatNumber variant="captionRegular">
                                            {intl.formatMessage({ id: 'ИНН:', defaultMessage: 'ИНН:' })}{' '}
                                            {counterparty.vatNumber}
                                        </S.OrganizationVatNumber>
                                    </S.OrganizationData>
                                    <S.OrganizationTags>
                                        {counterparty.id === selectedCounterparty?.id && <CheckIcon />}
                                        <S.OrganizationLabel variant="captionRegular">
                                            {intl.formatMessage({ id: 'ЮР', defaultMessage: 'ЮР' })}
                                        </S.OrganizationLabel>
                                    </S.OrganizationTags>
                                </S.OrganizationWrapper>
                            ))}
                        </S.OrganizationList>
                    </Scrollbar>
                    <S.Divider />
                </>
            )}
            <S.ActionButton type="button" onClick={onProfileGoTo}>
                <UserIcon />
                <Typography variant="bodyMRegular">
                    {intl.formatMessage({ id: 'Профиль', defaultMessage: 'Профиль' })}
                </Typography>
            </S.ActionButton>
            <S.Divider />
            <S.ActionButton type="button" onClick={onLogout}>
                <LogoutIcon />
                <Typography variant="bodyMRegular">
                    {intl.formatMessage({ id: 'Выйти', defaultMessage: 'Выйти' })}
                </Typography>
            </S.ActionButton>
        </div>
    );
};
