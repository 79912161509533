import React, { FC } from 'react';
import { InvoiceboxLogo } from '@invoicebox/ui';
import * as S from './styles';
import { ReactComponent as CloseIcon } from './assets/cross.svg';

export type TProps = {
    onClose: () => void;
};

export const DrawerMenuLogoHeader: FC<TProps> = ({ onClose }) => (
    <S.Head>
        <S.LogoWrapper>
            <InvoiceboxLogo height={26} />
        </S.LogoWrapper>
        <S.CloseButton type="button" onClick={onClose}>
            <CloseIcon />
        </S.CloseButton>
    </S.Head>
);
