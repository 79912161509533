import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    position: relative;
`;

export const MenuItemsWrapper = styled.div`
    padding: 7px 0 11px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    position: relative;
    z-index: 2;
    border-top: 1px solid #d6d6d6;
    background: rgba(255, 255, 255);

    &:after {
        content: '';
        width: 66px;
        height: 66px;
        border-radius: 50%;
        background: rgba(255, 255, 255);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -23px;
    }
`;

export const MenuButtonLabel = styled(Typography)``;

export const MenuButton = styled.button<{ $isActive: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: ${({ $isActive, theme }) => ($isActive ? theme.colors.yellow() : theme.colors.grey700())};

    ${MenuButtonLabel} {
        color: ${({ $isActive, theme }) => ($isActive ? '#E9C342' : theme.colors.grey700())};
    }
`;

export const PrimaryButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    position: absolute;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    top: -18px;
    width: 55px;
    height: 55px;
    background: rgba(255, 255, 255);
    border-radius: 50%;
`;

export const MainButtonHalfCircle = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -24px;
    width: 67px;
    height: 67px;
    border-radius: 50%;
    border: 1px solid #d6d6d6;
    border-bottom: none;
    background: rgba(255, 255, 255);
`;
