import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/store';
import { fetchOneFeed, subscribeNotifications } from 'src/store/Feed';
import { getNoun } from 'src/utils/helpers';
import { IFeedItem } from 'src/store/Feed/types';
import { useNavigate } from 'react-router-dom';
import NotificationWindow from 'src/components/NotificationMenuItem/components/NotificationWindow';
import { useIntl } from 'react-intl';
import { toast } from '@invoicebox/ui';
import { useSelector } from 'react-redux';
import { ReactComponent as IconNewNotification } from './assets/notificationNew.svg';
import { ReactComponent as IconNotification } from './assets/notification.svg';
import * as S from './styles';
import { isSupportedFirebase, onMessageListener } from '../../firebaseInit';
import { BUSINESS_OFFICE_PATHS } from '../../pages/businessOffice/paths';
import { getCurrentCounterpartyId } from '../../store/Counterparty/selectors';

const NotificationMenuItem = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const [newFeedsNotification, setNewFeedsNotification] = useState<IFeedItem[]>([]);

    const [feedsIconNotification, setFeedsIconNotification] = useState<IFeedItem[]>([]);
    const [isShowWindowNotifications, setIsShowWindowNotifications] = useState(false);

    useEffect(() => {
        if (isSupportedFirebase) {
            dispatch(subscribeNotifications());
        }
    }, [dispatch]);

    // отображение фидов в открытом приложении каждые 30 секунд
    useEffect(() => {
        if (isSupportedFirebase) {
            const queueFeedsInterval = setInterval(() => {
                if (newFeedsNotification.length > 1) {
                    setFeedsIconNotification(newFeedsNotification);
                    toast.success(
                        `${intl.formatMessage({ defaultMessage: 'У вас' })} ${newFeedsNotification.length} ${getNoun(
                            newFeedsNotification.length,
                            intl.formatMessage({ defaultMessage: 'новое уведомление' }),
                            intl.formatMessage({ defaultMessage: 'новых уведомления' }),
                            intl.formatMessage({ defaultMessage: 'новых уведомлений' }),
                        )}`,
                    );
                }
                if (newFeedsNotification.length === 1) {
                    toast.success(newFeedsNotification[0]?.description);
                    setFeedsIconNotification(newFeedsNotification);
                }
            }, 30000);

            return () => {
                clearInterval(queueFeedsInterval);
            };
        }
    }, [dispatch, intl, newFeedsNotification]);

    // слушатель на пуши при открытом приложении
    if (isSupportedFirebase) {
        onMessageListener().then((payload) => {
            dispatch(fetchOneFeed(payload.data.feedItemId)).then((data) =>
                // @ts-ignore
                setNewFeedsNotification([...newFeedsNotification, data?.payload[0] as IFeedItem]),
            );
        });
    }

    const onClickIconNotifications = () => {
        if (feedsIconNotification.length > 0) {
            setIsShowWindowNotifications(true);
            setFeedsIconNotification([]);
        } else {
            navigate(BUSINESS_OFFICE_PATHS.feeds(counterpartyId));
        }
    };

    return (
        <S.NotificationWrapper>
            <S.NotificationButton type="button" onClick={onClickIconNotifications}>
                {feedsIconNotification.length === 0 ? <IconNotification /> : <IconNewNotification />}
            </S.NotificationButton>
            <NotificationWindow
                isShowWindowNotifications={isShowWindowNotifications}
                setIsShowWindowNotifications={setIsShowWindowNotifications}
                feeds={feedsIconNotification}
            />
        </S.NotificationWrapper>
    );
};

export default NotificationMenuItem;
