import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';
import Button from '../../common/Button';
import { AdaptiveModalDrawer } from '../../common/AdaptiveModalDrawer';
import { useMobile } from '../../../hooks/useMedia';

export type TProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const DocumentsBeingPreparedModal: FC<TProps> = ({ isOpen, onClose }) => {
    const intl = useIntl();
    const isMobile = useMobile();

    return (
        <AdaptiveModalDrawer isOpen={isOpen} onClose={onClose} drawerProps={{ isPadding: false }}>
            <S.ModalInner>
                <S.Header />
                <S.Content>
                    <S.Texts>
                        <Typography variant={isMobile ? 'headline2' : 'headline6'}>
                            {intl.formatMessage({
                                id: 'Документы оформляются',
                                defaultMessage: 'Документы оформляются',
                            })}
                        </Typography>
                        <S.Description variant="bodyMRegular">
                            {intl.formatMessage({
                                id: 'Ваш запрос на оформление документов успешно отправлен. Когда процесс завершится, ссылка для скачивания появится в этом разделе и продублируется на вашу электронную почту',
                                defaultMessage:
                                    'Ваш запрос на оформление документов успешно отправлен. Когда процесс завершится, ссылка для скачивания появится в этом разделе и продублируется на вашу электронную почту',
                            })}
                        </S.Description>
                    </S.Texts>

                    <Button size={isMobile ? 'large' : 'small'} fullWidth={isMobile} onClick={onClose} type="secondary">
                        {intl.formatMessage({ id: 'Закрыть', defaultMessage: 'Закрыть' })}
                    </Button>
                </S.Content>
            </S.ModalInner>
        </AdaptiveModalDrawer>
    );
};
