import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Arrow } from '@invoicebox/ui';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { ReactComponent as ListIcon } from './assets/list.svg';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { ReactComponent as HomeIcon } from './assets/home.svg';
import { ReactComponent as SupportIcon } from './assets/support.svg';
import { TMenuItems } from '../MoreMenu';
import { SidebarMenuItem } from '../SidebarMenuItem';
import { ActionWrapper } from '../ActionWrapper';
import { HELP_LINK } from '../../../utils/constants';

export type TProps = {
    menuItems: TMenuItems;
    onCreateInvoice?: () => void;
    onHomeGoTo: () => void;
    isBuyer: boolean;
};

export const SidebarMenu: FC<TProps> = ({ menuItems, onCreateInvoice, onHomeGoTo, isBuyer }) => {
    const intl = useIntl();
    const theme = useTheme();
    const [selectedMenuItemId, setSelectedMenuItemId] = useState<number | undefined>();

    const secondaryMenuItems = menuItems.find((menuItem) => menuItem.id === selectedMenuItemId)?.children;

    const handleSupportClick = () => {
        window.open(HELP_LINK, '_blank');
    };

    return (
        <S.Wrapper $isOpenedSecondaryItems={!!secondaryMenuItems} onMouseLeave={() => setSelectedMenuItemId(undefined)}>
            <S.MainMenuItemsWrapper>
                {onCreateInvoice && !isBuyer && (
                    <>
                        <ActionWrapper onClick={onCreateInvoice}>
                            <SidebarMenuItem
                                onMouseEnter={() => setSelectedMenuItemId(undefined)}
                                icon={<ListIcon />}
                                label={intl.formatMessage({ id: 'Оформить счёт', defaultMessage: 'Оформить счёт' })}
                                rightIcon={<PlusIcon />}
                            />
                        </ActionWrapper>
                        <S.Divider />
                    </>
                )}
                <ActionWrapper onClick={onHomeGoTo}>
                    <SidebarMenuItem
                        onMouseEnter={() => setSelectedMenuItemId(undefined)}
                        icon={<HomeIcon />}
                        label={intl.formatMessage({ id: 'Главная', defaultMessage: 'Главная' })}
                    />
                </ActionWrapper>
                {menuItems.map((menuItem) => (
                    <SidebarMenuItem
                        key={menuItem.id}
                        onMouseEnter={() => setSelectedMenuItemId(menuItem.id)}
                        icon={menuItem.icon}
                        label={menuItem.label}
                        rightIcon={
                            menuItem.children ? (
                                <Arrow
                                    palette={{ open: theme.colors.base() }}
                                    outterSize={24}
                                    isOpen
                                    defaultRotate={menuItem.id === selectedMenuItemId ? 90 : 180}
                                />
                            ) : undefined
                        }
                    />
                ))}
                <S.Divider />
                <ActionWrapper onClick={handleSupportClick}>
                    <SidebarMenuItem
                        onMouseEnter={() => setSelectedMenuItemId(undefined)}
                        label={intl.formatMessage({ id: 'Справка и поддержка', defaultMessage: 'Справка и поддержка' })}
                        icon={<SupportIcon />}
                    />
                </ActionWrapper>
            </S.MainMenuItemsWrapper>
            <S.SecondaryMenuItemsWrapper>
                {secondaryMenuItems?.map((menuItem) => (
                    <ActionWrapper key={menuItem.id} href={menuItem.href} onClick={menuItem.onClick}>
                        <SidebarMenuItem icon={menuItem.icon} label={menuItem.label} />
                    </ActionWrapper>
                ))}
            </S.SecondaryMenuItemsWrapper>
        </S.Wrapper>
    );
};
