import React, { FC } from 'react';
import * as S from './styles';
import { DrawerMenuLogoHeader, TProps as TDrawerMenuLogoHeaderProps } from '../DrawerMenuLogoHeader';
import { DrawerMenuContainer, TProps as TDrawerMenuContainerProps } from '../../../common/DrawerMenuContainer';

export type TProps = Pick<TDrawerMenuContainerProps, 'isOpen'> &
    TDrawerMenuLogoHeaderProps & {
        menuLinks: Array<{ href: string; label: string }>;
    };

export const BurgerMenu: FC<TProps> = ({ isOpen, onClose, menuLinks }) => {
    const handleNavLinkClick = () => {
        onClose();
    };

    return (
        <DrawerMenuContainer isOpen={isOpen} header={<DrawerMenuLogoHeader onClose={onClose} />}>
            <S.MenuItems>
                {menuLinks.map((menuItem) => (
                    <S.MenuItem key={menuItem.label} to={menuItem.href} onClick={handleNavLinkClick}>
                        {menuItem.label}
                    </S.MenuItem>
                ))}
            </S.MenuItems>
        </DrawerMenuContainer>
    );
};
