import React, { FC } from 'react';
import { Arrow } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { APP_VARIANT_ROUTES, RoutesBusinessOffice } from 'src/pages/constants';
import { useMobile } from 'src/hooks/useMedia';
import { ProfileMenuItem } from 'src/components/ProfileMenuItem';
import * as S from './styles';
import { ReactComponent as UserIcon } from './assets/avatar.svg';
import { BurgerButton, TProps as TBurgerButtonProps } from '../BurgerButton';
import { NameCircle } from '../../common/NameCircle';

export type TProps = Partial<Pick<TBurgerButtonProps, 'menuLinks'>> & {
    userName: string | null;
    onLogout: () => void;
};

export const DocsHeaderAction: FC<TProps> = ({ userName, menuLinks, onLogout }) => {
    const intl = useIntl();
    const isMobile = useMobile();
    const theme = useTheme();

    const arrowPalette = {
        close: theme.colors.base(),
    };

    const desktopUserButton = (
        <S.UserWrapper>
            <S.UserName variant="buttonM">
                {userName || intl.formatMessage({ id: 'Войти', defaultMessage: 'Войти' })}
            </S.UserName>
            <S.UserArrowWrapper>
                <Arrow palette={arrowPalette} isOpen={false} outterSize={20} defaultRotate={userName ? 0 : 270} />
            </S.UserArrowWrapper>
        </S.UserWrapper>
    );

    const mobileUserButton = (
        <S.MobileUserWrapper>{userName ? <NameCircle name={userName} /> : <UserIcon />}</S.MobileUserWrapper>
    );
    const userButton = isMobile ? mobileUserButton : desktopUserButton;

    return (
        <S.MobileActionsWrapper>
            {userName ? (
                <ProfileMenuItem profileLink={RoutesBusinessOffice.personal} logout={onLogout}>
                    {userButton}
                </ProfileMenuItem>
            ) : (
                <S.LoginButton href={APP_VARIANT_ROUTES.auth}>{userButton}</S.LoginButton>
            )}
            {!!menuLinks?.length && isMobile && (
                <>
                    <S.MobileActionsDivider />
                    <BurgerButton menuLinks={menuLinks} />
                </>
            )}
        </S.MobileActionsWrapper>
    );
};
