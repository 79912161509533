import React from 'react';
import { Drawer, Typography } from '@invoicebox/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { QrReader } from 'react-qr-reader';
import Result from '@zxing/library/esm/core/Result';
import { BottomMenu } from '../../stories/common/BottomMenu';
import * as S from './styles';
import useModal from '../../hooks/useModal';
import { BUSINESS_OFFICE_PATHS } from '../../pages/businessOffice/paths';
import { getCurrentCounterpartyId } from '../../store/Counterparty/selectors';
import { scrollToTop } from '../../utils/scrollToTop';
import { RoutesBusinessOffice } from '../../pages/constants';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { ReactComponent as QRIcon } from './assets/qr.svg';
import { getOrderContainerIdFromUrl } from '../../utils/getOrderContainerIdFromUrl';
import MoreMenuDrawer from '../MoreMenuDrawer';
import { useIsBuyer } from '../../hooks/useIsBuyer';

const getActiveMenuItem = (pathname: string) => {
    if (pathname.includes(RoutesBusinessOffice.dashboard)) {
        return 'home';
    }

    if (pathname.includes(RoutesBusinessOffice.payment)) {
        return 'bills';
    }

    return undefined;
};

const BottomMenuWidget = () => {
    const { isOpen: isOpenMainButtonMenu, open: openMainButtonMenu, close: onCloseMainButtonMenu } = useModal(false);
    const { isOpen: isOpenScanQR, open: openScanQR, close: onCloseScanQR } = useModal(false);
    const { isOpen: isOpenMoreMenu, open: openMoreMenu, close: onCloseMoreMenu } = useModal(false);

    const navigate = useNavigate();
    const intl = useIntl();
    const location = useLocation();

    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const isBuyer = useIsBuyer();

    const handleOpenScanQr = () => {
        onCloseMainButtonMenu();
        openScanQR();
    };

    const mainButtonMenuItems = [
        {
            label: intl.formatMessage({ id: 'Оформить счёт', defaultMessage: 'Оформить счёт' }),
            icon: <PlusIcon />,
        },
        {
            label: intl.formatMessage({ id: 'Сканировать QR-код', defaultMessage: 'Сканировать QR-код' }),
            icon: <QRIcon />,
            onClick: handleOpenScanQr,
        },
    ];

    const handleBillListGoTo = () => navigate(BUSINESS_OFFICE_PATHS.payment(counterpartyId));

    const handleHomeButtonClick = () => {
        navigate(BUSINESS_OFFICE_PATHS.dashboard(counterpartyId));
        scrollToTop();
    };

    const handleScanSuccess = (result: Result | null | undefined) => {
        if (result) {
            const orderContainerId = getOrderContainerIdFromUrl(result.getText());
            if (orderContainerId) {
                navigate(BUSINESS_OFFICE_PATHS.orderContainer(orderContainerId, counterpartyId));
            }

            onCloseScanQR();
        }
    };

    return (
        <>
            <S.MenuWrapper>
                <BottomMenu
                    activeMenuItem={getActiveMenuItem(location.pathname)}
                    onHomeButtonClick={handleHomeButtonClick}
                    onPrimaryButtonClick={isBuyer ? openScanQR : openMainButtonMenu}
                    isBuyer={isBuyer}
                    onMoreClick={openMoreMenu}
                    // пока нет раздела исходящих счетов - дублируем ссылка на входящие
                    onOrderListGoTo={handleBillListGoTo}
                    onBillListGoTo={handleBillListGoTo}
                />
            </S.MenuWrapper>

            <Drawer onClose={onCloseMainButtonMenu} isOpen={isOpenMainButtonMenu}>
                {mainButtonMenuItems.map((menuItem) => (
                    <S.DrawerButton type="button" key={menuItem.label} onClick={menuItem.onClick}>
                        {menuItem.icon}
                        <Typography variant="bodyL">{menuItem.label}</Typography>
                    </S.DrawerButton>
                ))}
            </Drawer>

            <Drawer onClose={onCloseScanQR} isOpen={isOpenScanQR}>
                <QrReader constraints={{ facingMode: 'environment' }} onResult={handleScanSuccess} />
            </Drawer>

            <MoreMenuDrawer isOpen={isOpenMoreMenu} onClose={onCloseMoreMenu} isBuyer={isBuyer} />
        </>
    );
};

export default BottomMenuWidget;
