import React, { FC, useState } from 'react';
import * as S from './styles';
import { ReactComponent as BurgerIcon } from './assets/burger.svg';
import { BurgerMenu } from '../BurgerMenu';

export type TProps = {
    menuLinks: Array<{ href: string; label: string }>;
};

export const BurgerButton: FC<TProps> = ({ menuLinks }) => {
    const [isOpenBurger, setIsOpenBurger] = useState(false);

    const toggleBurger = () => setIsOpenBurger((prev) => !prev);
    const closeBurger = () => setIsOpenBurger(false);

    return (
        <>
            <S.BurgerButton type="button" onClick={toggleBurger}>
                <BurgerIcon />
            </S.BurgerButton>
            <BurgerMenu isOpen={isOpenBurger} onClose={closeBurger} menuLinks={menuLinks} />
        </>
    );
};
