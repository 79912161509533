import styled from 'styled-components';
import Skeleton from 'src/components/Skeleton';

export const SkeletonRefundItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 140px 150px 100px;
    justify-content: space-between;
    padding: 18px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
`;

export const SkeletonItemValue = styled(Skeleton)``;
