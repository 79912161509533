import React, { FC, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import * as S from './styles';
import RequisiteStep from './components/RequisiteStep';
import AuthStep from './components/AuthStep';

enum STEP {
    requisite = 'requisite',
    auth = 'auth',
}

type TProps = {
    initialStep: keyof typeof STEP;
};

const AuthDocs: FC<TProps> = ({ initialStep }) => {
    const intl = useIntl();
    const [step] = useState<keyof typeof STEP>(initialStep);

    const stepMap: Record<STEP, ReactNode> = {
        [STEP.auth]: <AuthStep />,
        [STEP.requisite]: <RequisiteStep />,
    };

    return (
        <S.Wrapper>
            <S.AuthPageContainer>
                <S.Content>
                    <div>
                        <S.Title>{intl.formatMessage({ defaultMessage: 'Оформить отчётные документы' })}</S.Title>
                        {stepMap[step]}
                    </div>
                    <S.Note variant="captionRegular">
                        {intl.formatMessage({
                            defaultMessage:
                                'Отчётные документы доступны только при оплате счёта переводом по безналичному расчёту получателем которых является ООО «ОРЦ» (ИНН 7810532221) или ООО «ЭТС» (ИНН 7810774608). В случае оплаты счёта банковской картой - отчётные документы не предоставляются. В случае, если получатель денежных средств - иное юридическое лицо, для получения отчётных документов, вам необходимо обратиться к представителям организации-получателя платежа.',
                        })}
                    </S.Note>
                </S.Content>
            </S.AuthPageContainer>
        </S.Wrapper>
    );
};

export default AuthDocs;
