import React, { FC, ReactNode } from 'react';
import * as S from './styles';

export type TProps = {
    icon?: ReactNode;
    label: string;
    onMouseEnter?: () => void;
    rightIcon?: ReactNode;
};

export const SidebarMenuItem: FC<TProps> = ({ icon, label, onMouseEnter, rightIcon }) => (
    <S.Wrapper onMouseEnter={onMouseEnter}>
        {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
        <S.Label variant="bodyMRegular">{label}</S.Label>
        {!!rightIcon && <S.MenuItemRightIconWrapper>{rightIcon}</S.MenuItemRightIconWrapper>}
    </S.Wrapper>
);
