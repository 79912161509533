import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { BusinessOfficeMobileHeader } from '../../stories/header/businessOffice/BusinessOfficeMobileHeader';
import { DrawerMenuContainer } from '../../stories/common/DrawerMenuContainer';
import {
    UserSectionMenuMobile,
    TProps as TUserSectionMenuMobileProps,
} from '../../stories/header/businessOffice/UserSectionMenuMobile';
import { DrawerMenuTitleHeader } from '../../stories/header/businessOffice/DrawerMenuTitleHeader';

type TProps = TUserSectionMenuMobileProps & {
    isOpenUserDrawer: boolean;
    openUserDrawer: () => void;
    closeUserDrawer: () => void;
};

const BusinessOfficeMobileHeaderWidget: FC<TProps> = ({
    userName,
    isOpenUserDrawer,
    openUserDrawer,
    closeUserDrawer,
    ...args
}) => {
    const intl = useIntl();

    return (
        <>
            <DrawerMenuContainer
                isOpen={isOpenUserDrawer}
                header={
                    <DrawerMenuTitleHeader
                        title={intl.formatMessage({ id: 'Выбор профиля', defaultMessage: 'Выбор профиля' })}
                        onBack={closeUserDrawer}
                    />
                }
            >
                <UserSectionMenuMobile userName={userName} {...args} />
            </DrawerMenuContainer>
            <BusinessOfficeMobileHeader name={userName} onUserClick={openUserDrawer} />
        </>
    );
};

export default BusinessOfficeMobileHeaderWidget;
