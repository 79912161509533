import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as BagIcon } from './assets/bag.svg';
// import { ReactComponent as CardIcon } from './assets/card.svg';
// import { ReactComponent as CompanyIcon } from './assets/company.svg';
// import { ReactComponent as TilesIcon } from './assets/tiles.svg';
// import { ReactComponent as NSPKIcon } from './assets/nspk.svg';
import { BUSINESS_OFFICE_PATHS } from '../../pages/businessOffice/paths';
import { getCurrentCounterpartyId } from '../../store/Counterparty/selectors';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
export const useMenuItems = (isBuyer: boolean) => {
    const intl = useIntl();
    const selectedCounterpartyId = useSelector(getCurrentCounterpartyId);

    return [
        // todo comment absent sections
        // !isBuyer && {
        //     id: 1,
        //     label: intl.formatMessage({ id: 'Мои продажи', defaultMessage: 'Мои продажи' }),
        //     icon: <BagIcon />,
        //     children: [
        //         {
        //             id: 11,
        //             label: intl.formatMessage({ id: 'Исходящие счета', defaultMessage: 'Исходящие счета' }),
        //         },
        //         {
        //             id: 12,
        //             label: intl.formatMessage({ id: 'Возвраты', defaultMessage: 'Возвраты' }),
        //         },
        //         {
        //             id: 13,
        //             label: intl.formatMessage({ id: 'Выплаты', defaultMessage: 'Выплаты' }),
        //         },
        //         {
        //             id: 14,
        //             label: intl.formatMessage({ id: 'Клиенты', defaultMessage: 'Клиенты' }),
        //         },
        //         {
        //             id: 15,
        //             label: intl.formatMessage({ id: 'Магазины', defaultMessage: 'Магазины' }),
        //         },
        //     ],
        // },
        // !isBuyer && {
        //     id: 2,
        //     label: intl.formatMessage({ id: 'Онлайн-касса', defaultMessage: 'Онлайн-касса' }),
        //     icon: <CardIcon />,
        //     children: [
        //         {
        //             id: 21,
        //             label: intl.formatMessage({ id: 'Чеки', defaultMessage: 'Чеки' }),
        //         },
        //     ],
        // },
        // !isBuyer && {
        //     id: 3,
        //     label: intl.formatMessage({ id: 'Мои площадки', defaultMessage: 'Мои площадки' }),
        //     icon: <TilesIcon />,
        //     children: [
        //         {
        //             id: 31,
        //             label: intl.formatMessage({ id: 'Площадки', defaultMessage: 'Площадки' }),
        //         },
        //         {
        //             id: 32,
        //             label: intl.formatMessage({ id: 'Категории', defaultMessage: 'Категории' }),
        //         },
        //         {
        //             id: 33,
        //             label: intl.formatMessage({ id: 'Товары', defaultMessage: 'Товары' }),
        //         },
        //     ],
        // },
        {
            id: 4,
            label: intl.formatMessage({ id: 'Мои покупки', defaultMessage: 'Мои покупки' }),
            icon: <BagIcon />,
            children: [
                {
                    id: 41,
                    label: intl.formatMessage({ id: 'Входящие счета', defaultMessage: 'Входящие счета' }),
                    href: BUSINESS_OFFICE_PATHS.payment(selectedCounterpartyId),
                },
            ],
        },
        // {
        //     id: 5,
        //     label: intl.formatMessage({ id: 'Организация', defaultMessage: 'Организация' }),
        //     icon: <CompanyIcon />,
        //     children: [
        //         {
        //             id: 51,
        //             label: intl.formatMessage({ id: 'Карточка', defaultMessage: 'Карточка' }),
        //         },
        //         {
        //             id: 52,
        //             label: intl.formatMessage({ id: 'Договоры', defaultMessage: 'Договоры' }),
        //         },
        //         {
        //             id: 53,
        //             label: intl.formatMessage({ id: 'Отчётные документы', defaultMessage: 'Отчётные документы' }),
        //         },
        //         {
        //             id: 54,
        //             label: intl.formatMessage({ id: 'Группы пользователей', defaultMessage: 'Группы пользователей' }),
        //         },
        //         {
        //             id: 55,
        //             label: intl.formatMessage({ id: 'Пользователи', defaultMessage: 'Пользователи' }),
        //         },
        //         {
        //             id: 56,
        //             label: intl.formatMessage({ id: 'Интеграция и API', defaultMessage: 'Интеграция и API' }),
        //         },
        //     ],
        // },
        // {
        //     id: 6,
        //     label: intl.formatMessage({ id: 'Запрос о платеже', defaultMessage: 'Запрос о платеже' }),
        //     icon: <NSPKIcon />,
        //     children: [
        //         {
        //             id: 61,
        //             label: intl.formatMessage({ id: 'Как работает сервис?', defaultMessage: 'Как работает сервис?' }),
        //         },
        //         {
        //             id: 62,
        //             label: intl.formatMessage({ id: 'Управление шаблонами', defaultMessage: 'Управление шаблонами' }),
        //         },
        //         {
        //             id: 63,
        //             label: intl.formatMessage({ id: 'Управление счетами', defaultMessage: 'Управление счетами' }),
        //         },
        //         {
        //             id: 64,
        //             label: intl.formatMessage({ id: 'Уведомления', defaultMessage: 'Уведомления' }),
        //         },
        //     ],
        // },
        // {
        //     id: 7,
        //     label: intl.formatMessage({ id: 'Продукты и услуги', defaultMessage: 'Продукты и услуги' }),
        //     icon: <TilesIcon />,
        // },
    ];
};
