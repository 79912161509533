import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as S from './styles';
import { fetchCounterpartiesList } from '../../store/Counterparty';
import { useAppDispatch } from '../../store';
import { getIsCounterpartyInitialized } from '../../store/Counterparty/selectors';
import Loader from '../Loader';
import { fetchCurrencyList } from '../../store/Currency';
import { getIsCurrencyInitialized } from '../../store/Currency/selectors';
import { getIsInitializedFlag } from '../../utils/helpers/getIsInitializedFlag';
import { fetchTaskFlowList } from '../../store/TaskFlow';
import { fetchDocumentGeneratorSettingList } from '../../store/DocumentGeneratorSetting';

const DocsAuthLayout: FC = ({ children }) => {
    const dispatch = useAppDispatch();
    const isInitializedCounterparty = useSelector(getIsCounterpartyInitialized);
    const isInitializedCurrency = useSelector(getIsCurrencyInitialized);

    useEffect(() => {
        dispatch(fetchCounterpartiesList());
        dispatch(fetchCurrencyList());
        dispatch(fetchTaskFlowList());
        dispatch(fetchDocumentGeneratorSettingList());
    }, [dispatch]);

    if (!getIsInitializedFlag(isInitializedCurrency, isInitializedCounterparty)) {
        return <Loader />;
    }

    return (
        <S.Wrapper>
            <S.ContentWrapper>{children}</S.ContentWrapper>
        </S.Wrapper>
    );
};

export default DocsAuthLayout;
