import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const RowContactWrapper = styled.div`
    display: grid;
    align-items: center;
    grid-column-gap: 20px;
    border: 1px solid ${({ theme }) => theme.colors.grey200()};
    border-bottom: none;
    padding: 20px 30px;
    grid-template-columns: auto 135px 180px 16px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 20px 20px 12px 20px;
        grid-template-columns: auto 16px;
        grid-row-gap: 18px;
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    }
`;

export const Title = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
`;

export const IconWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.grey200()};
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    svg {
        width: 16px;
        height: 16px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 30px;
        height: 30px;
        svg {
            width: 13px;
            height: 13px;
        }
    }
`;

export const Phone = styled(Typography)``;

export const Email = styled(Typography)`
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-start: 1;
        grid-column-end: 2;
    }
`;

export const ButtonRemove = styled.button`
    width: 16px;
    height: 16px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }
`;
