export const PARAM_COUNTERPARTY_ID_NAME = 'paramCounterpartyId';
export const USER_PARAM_NAME = 'user';

export enum AUTH_TYPE_FLOW {
    buyer = 'buyer',
    merchant = 'merchant',
}

export type TAuthTypeFlow = keyof typeof AUTH_TYPE_FLOW;
export const AUTH_TYPE_FLOW_VARIABLE = 'authTypeFlow';

export enum APP_VARIANT_ROUTES {
    auth = '/auth',
    businessOffice = '/businessOffice',
    docs = '/docs',
}

export const RoutesDocs = {
    // main
    main: `/main`,
    dashboard: `/dashboard`,
    statuses: `/statuses`,
    getDocuments: `/getDocuments`,
};

export const RoutesBusinessOffice = {
    // routes first level
    profile: `/profile`,
    dashboard: `/dashboard`,
    introduce: `/introduce`,
    payment: `/payment`,
    shop: `/shop`,
    orderContainer: `/orderContainer`,
    refundCreate: `/refundCreate`,
    counterparty: `/counterparty`,
    feeds: `/feeds`,
    claim: `/claim`,

    // routes for profile
    personal: `/personal`,
    organizations: `/organizations`,

    // routes for shop
    overview: '/overview',
    orders: '/orders',
    refunds: '/refunds',
    clients: '/clients',
    orderToPay: '/orderToPay',
    settings: '/settings',
    integrations: '/integrations',
    createClient: '/createClient',
    createCounterparty: '/createCounterparty',
    detailClient: '/detailClient',
    detailClientCounterparty: '/detailClientCounterparty',
    refundDetail: '/refundDetail',
    createIntegration: '/createIntegration',
};
