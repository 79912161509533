import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SidebarMenu } from '../../stories/menu/SidebarMenu';
import { useMenuItems } from '../../hooks/useMenuItems';
import { BUSINESS_OFFICE_PATHS } from '../../pages/businessOffice/paths';
import { scrollToTop } from '../../utils/scrollToTop';
import { getCurrentCounterpartyId } from '../../store/Counterparty/selectors';
import { useIsBuyer } from '../../hooks/useIsBuyer';

const SidebarMenuWidget = () => {
    const navigate = useNavigate();

    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const isBuyer = useIsBuyer();

    const menuItems = useMenuItems(isBuyer);

    const handleHomeButtonClick = () => {
        navigate(BUSINESS_OFFICE_PATHS.dashboard(counterpartyId));
        scrollToTop();
    };

    return <SidebarMenu isBuyer={isBuyer} onHomeGoTo={handleHomeButtonClick} menuItems={menuItems} />;
};

export default SidebarMenuWidget;
