import styled from 'styled-components';

interface IButtonNumberPage {
    isActive?: boolean;
}

export const ButtonNumberPage = styled.button<IButtonNumberPage>`
    padding: 4px 8px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    background: ${({ theme, isActive }) => (isActive ? theme.colors.yellow() : theme.colors.white())};
    &:hover {
        background: ${({ theme }) => theme.colors.grey200()};
    }
`;
