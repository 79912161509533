import http, { ICommonResponse } from '../../utils/http';
import { TTaskFlowCode } from '../TaskFlow/types';
import { TTask } from './types';

export type TCreateTaskParams = {
    taskflowCode: TTaskFlowCode;
    data: { [key: string]: any };
    counterpartyId: string;
};

export const createTaskRequest = (params: TCreateTaskParams) =>
    http.post<ICommonResponse<TTask>>('/document/paymentgateway/taskflow/task', params);

export type TTaskFilter = {
    'createdAt[_ge]'?: string;
    'createdAt[lt]'?: string;
};

export const getTasksRequest = (params: TTaskFilter) =>
    http.get<ICommonResponse<Array<TTask>>>('/document/paymentgateway/taskflow/task', {
        params: {
            _include: ['task-place', 'task-place-notification'],
            '_order[createdAt]': 'desc',
            ...params,
        },
    });
