import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';
import {
    createOrUpdateMerchantContractRequest,
    fetchMerchantContractsRequest,
    fetchMerchantContractTemplatesRequest,
    removeMerchantContractRequest,
} from './http';
import { IContract, ICreateOrUpdateContractInfo } from './types';

export const contractsAdapter = createEntityAdapter<IContract>();
const initialState = contractsAdapter.getInitialState({
    contractTemplates: [] as Array<IContract> | [],
    isLoading: false,
    isLoaded: false,
    selectedId: null as null | number,
    selectedContractTemplateId: null as null | number,
});

export const fetchMerchantContractsList = createAsyncThunkWithErrorHandler<Array<IContract>>(
    'contracts/getContracts',
    async (arg, thunkAPI) => {
        if (thunkAPI.getState().counterparty.ids.length > 0) {
            const {
                data: { data },
            } = await fetchMerchantContractsRequest();
            return data;
        }
        throw new Error('You need have organizations for getting merchant contract list');
    },
);

export const fetchMerchantContractTemplates = createAsyncThunkWithErrorHandler<Array<IContract>>(
    'contracts/getContractTemplates',
    async () => {
        const {
            data: { data },
        } = await fetchMerchantContractTemplatesRequest();
        return data;
    },
);

export const createOrUpdateMerchantContract = createAsyncThunkWithErrorHandler<IContract, ICreateOrUpdateContractInfo>(
    'contracts/createOrUpdate',
    async (payload: any) => {
        const {
            data: { data },
        } = await createOrUpdateMerchantContractRequest(payload);
        return data;
    },
);

export const removeMerchantContract = createAsyncThunkWithErrorHandler('contracts/remove', async (id: number) => {
    await removeMerchantContractRequest(id);
    return id;
});

const contracts = createSlice({
    name: 'contracts',
    initialState,
    reducers: {
        clearContracts() {
            return initialState;
        },
        setSelectedContractTemplateId(state, action) {
            state.selectedContractTemplateId = action.payload;
        },
        setSelectedContractId(state, action) {
            state.selectedId = action.payload;
        },
        setContracts(state, { payload }) {
            contractsAdapter.upsertMany(state, payload);
        },
    },
    extraReducers: (builder) => {
        // fetchMerchantContracts
        builder.addCase(fetchMerchantContractsList.pending, (state) => {
            state.isLoaded = false;
            state.isLoading = true;
        });
        builder.addCase(fetchMerchantContractsList.fulfilled, (state, action) => {
            state.isLoaded = true;
            state.isLoading = false;
            contractsAdapter.addMany(state, action.payload);
        });
        builder.addCase(fetchMerchantContractsList.rejected, (state) => {
            state.isLoaded = true;
            state.isLoading = false;
        });
        // fetchMerchantContractTemplates
        builder.addCase(fetchMerchantContractTemplates.pending, (state) => {
            state.isLoading = true;
            state.isLoaded = false;
        });
        builder.addCase(fetchMerchantContractTemplates.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isLoaded = true;
            state.contractTemplates = action.payload;
        });
        builder.addCase(fetchMerchantContractTemplates.rejected, (state) => {
            state.isLoaded = true;
            state.isLoading = false;
        });
        // createMerchantContract
        builder.addCase(createOrUpdateMerchantContract.pending, (state) => {
            state.isLoading = true;
            state.isLoaded = false;
        });
        builder.addCase(createOrUpdateMerchantContract.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isLoaded = true;
            contractsAdapter.addOne(state, action.payload);
        });
        builder.addCase(createOrUpdateMerchantContract.rejected, (state) => {
            state.isLoaded = true;
            state.isLoading = false;
        });
        builder.addCase(removeMerchantContract.fulfilled, (state, action) => {
            contractsAdapter.removeOne(state, action.payload);
        });
    },
});

export const { setSelectedContractTemplateId, setSelectedContractId, setContracts, clearContracts } = contracts.actions;

export default contracts.reducer;
