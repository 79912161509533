import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Skeleton from 'src/components/Skeleton';

export const RefundDetailWrapper = styled.div`
    padding-top: 45px;
    width: 100%;
`;

export const RefundTitle = styled(Typography)`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 9px;
    margin-bottom: 50px;
`;

export const ButtonOrder = styled.button`
    ${({ theme }) => theme.mixins.getTypography('subheading')}
    color: ${({ theme }) => theme.colors.yellow()};
`;

export const SkeletonNumberOrder = styled(Skeleton).attrs({ width: 160 })``;

export const RefundInformation = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
`;

export const RefundHeader = styled.div`
    background: ${({ theme }) => theme.colors.grey200(0.5)};
    padding: 27px 30px;
    ${({ theme }) => theme.mixins.getTypography('title')}
`;

export const RefundContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 243px;
    padding: 40px 40px 46px 30px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        padding: 30px 0;
    }
`;

export const AboutRefund = styled.div`
    width: 100%;
    padding-right: 30px;
    border-right: 1px solid ${({ theme }) => theme.colors.grey200()};
    @media ${({ theme }) => theme.breakpoints.md} {
        border-right: none;
        padding: 0 20px;
        margin-bottom: 41px;
    }
`;

export const DescriptionRefund = styled.div`
    padding-left: 40px;
    width: 100%;
    @media ${({ theme }) => theme.breakpoints.md} {
        border-top: 1px solid ${({ theme }) => theme.colors.grey200()};
        padding: 30px 20px 0 20px;
    }
`;

export const Status = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200()};
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    padding-bottom: 40px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-bottom: 20px;
    }
`;

export const SkeletonStatus = styled(Skeleton).attrs({ width: 100 })``;

export const StatusValue = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 6px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    color: ${(props) => props.color};
`;

export const StatusIconWrapper = styled.div`
    position: relative;
`;

export const ReasonRefund = styled.div`
    padding-top: 40px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    color: ${({ theme }) => theme.colors.grey()};
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 20px;
    }
`;

export const RefundDescriptionSkeleton = styled(Skeleton)`
    width: 100%;
`;

export const ReasonTitle = styled(Typography)`
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.base()};
`;

export const AdaptiveStatusIconWrapper = styled.div`
    position: absolute;
    width: 9px;
    height: 9px;
    bottom: 4px;
    right: 2px;
    background: ${({ theme }) => theme.colors.white()};
    border-radius: 50%;
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;
