import React, { FC } from 'react';
import * as S from './styles';
import { ReactComponent as SearchIcon } from './assets/search.svg';

export type TProps = {
    onClick: () => void;
};

export const SearchButton: FC<TProps> = ({ onClick }) => (
    <S.IconButton type="button" onClick={onClick}>
        <SearchIcon />
    </S.IconButton>
);
