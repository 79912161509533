import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import auth from './Auth';
import counterparty from './Counterparty';
import merchant from './Merchant';
import orders from './Order';
import client from './Client';
import feed from './Feed';
import contracts from './Contracts';
import merchantSettlementBalances from './MerchantSettlementBalance';
import clientCounterparty from './ClientCounterparty';
import claims from './Claims';
import document from './Document';
import orderRefunds from './OrderRefunds';
import dictionaries from './Dictionaries';
import securityApiUserGroup from './SecurityApiUseGroup';
import application from './Application';
import orderContainer from './OrderContainer';
import currency from './Currency';
import taskFlow from './TaskFlow';
import task from './Task';
import country from './Country';
import nDocument from './NDocument';
import taskPlace from './TaskPlace';
import documentGeneratorSetting from './DocumentGeneratorSetting';

const store = configureStore({
    reducer: {
        auth,
        counterparty,
        feed,
        merchant,
        merchantSettlementBalances,
        orders,
        client,
        clientCounterparty,
        contracts,
        claims,
        document,
        orderRefunds,
        dictionaries,
        securityApiUserGroup,
        application,
        orderContainer,
        currency,
        taskFlow,
        task,
        country,
        nDocument,
        taskPlace,
        documentGeneratorSetting,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
