import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import * as S from './styles';
import { ReactComponent as HomeIcon } from './assets/home.svg';
import { ReactComponent as ChatIcon } from './assets/chat.svg';
import { ReactComponent as BaggageIcon } from './assets/baggage.svg';
import { ReactComponent as PointsIcon } from './assets/points.svg';
import { ReactComponent as QRIcon } from './assets/qr.svg';
import { ReactComponent as InvoiceBoxIcon } from './assets/invoicebox.svg';
import { HELP_LINK } from '../../../utils/constants';

export type TProps = {
    onPrimaryButtonClick: () => void;
    onMoreClick: () => void;
    onHomeButtonClick: () => void;
    onBillListGoTo: () => void;
    onOrderListGoTo: () => void;
    activeMenuItem?: 'home' | 'bills';
    isBuyer: boolean;
};

export const BottomMenu: FC<TProps> = ({
    onPrimaryButtonClick,
    onHomeButtonClick,
    onMoreClick,
    activeMenuItem,
    isBuyer,
    onBillListGoTo,
    onOrderListGoTo,
}) => {
    const intl = useIntl();

    const handleSupportClick = () => {
        window.open(HELP_LINK, '_blank');
    };

    const menuItems = [
        {
            label: intl.formatMessage({ id: 'Главная', defaultMessage: 'Главная' }),
            icon: <HomeIcon />,
            onClick: onHomeButtonClick,
            isActive: activeMenuItem === 'home',
        },
        {
            label: isBuyer
                ? intl.formatMessage({ id: 'Заказы', defaultMessage: 'Заказы' })
                : intl.formatMessage({ id: 'Счета', defaultMessage: 'Счета' }),
            icon: <BaggageIcon />,
            onClick: isBuyer ? onOrderListGoTo : onBillListGoTo,
            isActive: activeMenuItem === 'bills',
        },
        {
            label: '',
            icon: undefined,
        },
        {
            label: intl.formatMessage({ id: 'Поддержка', defaultMessage: 'Поддержка' }),
            icon: <ChatIcon />,
            onClick: handleSupportClick,
        },
        {
            label: intl.formatMessage({ id: 'Ещё', defaultMessage: 'Ещё' }),
            icon: <PointsIcon />,
            onClick: onMoreClick,
        },
    ];

    return (
        <S.Wrapper>
            <S.MenuItemsWrapper>
                {menuItems.map((menuItem) => (
                    <S.MenuButton $isActive={!!menuItem.isActive} key={menuItem.label} onClick={menuItem.onClick}>
                        {menuItem.icon}
                        <S.MenuButtonLabel variant="captionSRegular">{menuItem.label}</S.MenuButtonLabel>
                    </S.MenuButton>
                ))}
            </S.MenuItemsWrapper>

            <S.PrimaryButton onClick={onPrimaryButtonClick}>
                {isBuyer ? <QRIcon /> : <InvoiceBoxIcon />}
            </S.PrimaryButton>
            <S.MainButtonHalfCircle />
        </S.Wrapper>
    );
};
